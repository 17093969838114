/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import imgDiemDanh1 from '../../../assets/images/imgDiemDanh1.svg';
import imgDiemDanh2 from '../../../assets/images/imgDiemDanh2.svg';
import imgDiemDanh3 from '../../../assets/images/imgDiemDanh3.svg';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import PopupTinhTrangDiemDanhBox from './PopupTinhTrangDiemDanhBox';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	dung_gio: number;
	den_muon: number;
	vang_co_phep: number;
	vang_khong_phep: number;
	tong_cong: number;

}

const TinhTrangDiemDanhBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const [trangThai, setTrangThai] = useState(-1);
	const [expandedGrades, setExpandedGrades] = useState<{ [key: number]: boolean }>({});
	const toggleGrade = (gradeId: number) => {
		setExpandedGrades((prevState) => ({
			...prevState,
			[gradeId]: !prevState[gradeId],
		}));
	};
	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableTinhTrangDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = (idLopTmp: number, trangThaiTmp: number) => {
		setShowPopup(true)
		setTrangThai(trangThaiTmp)
		setIdLop(idLopTmp)
	}

	function calculateTotal(data: any[], dmKhoiId: number, loaiGiaTri: number): number {
		if (loaiGiaTri === 0) {
			return data
				.filter(item => item.dm_khoi_id === dmKhoiId)
				.reduce((total, item) => total + (item.chua_diem_danh || 0), 0);
		} else if (loaiGiaTri === 1) {
			return data
				.filter(item => item.dm_khoi_id === dmKhoiId)
				.reduce((total, item) => total + (item.dung_gio || 0), 0);
		} else if (loaiGiaTri === 2) {
			return data
				.filter(item => item.dm_khoi_id === dmKhoiId)
				.reduce((total, item) => total + (item.den_muon || 0), 0);
		} else if (loaiGiaTri === 3) {
			return data
				.filter(item => item.dm_khoi_id === dmKhoiId)
				.reduce((total, item) => total + (item.vang_co_phep || 0), 0);
		} else if (loaiGiaTri === 4) {
			return data
				.filter(item => item.dm_khoi_id === dmKhoiId)
				.reduce((total, item) => total + (item.vang_khong_phep || 0), 0);
		} else {
			return 0;
		}
	}

	return (
		<>
			<div className="col-xs-12 bg-frame">
				<div className="col-xs-12 box-content">
					<div className="box box-2 no-margin">
						{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
							<>
								<div className="title">Chi tiết tình trạng điểm danh</div>
							</>
						}
						{!isLoadingTableTheoHe && (!dataReport || dataReport.length === 0) && <NoDataLoading />}
						<div className="table-container">
							{isLoadingTableTheoHe &&
								<Loadingv2 />
							}
							{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
								<table>
									<thead>
										<tr>
											<th style={{ width: '150px' }}>Khối</th>
											<th>Hệ đào tạo</th>
											<th style={{ minWidth: '150px' }}>Lớp</th>
											<th className="text-center" style={{ width: '50px' }}>Chưa điểm danh</th>
											<th className="text-center" style={{ width: '50px' }}>Đúng giờ</th>
											<th className="text-center" style={{ width: '50px' }}>Đến muộn</th>
											<th className="text-center" style={{ width: '50px' }}>Vắng có phép</th>
											<th className="text-center" style={{ width: '50px' }}>Vắng không phép</th>
											<th className="text-center" style={{ width: '50px' }}>Tổng cộng</th>
										</tr>
									</thead>
									<tbody>
										{dataReport?.reduce<JSX.Element[]>((acc, item, index) => {
											const isTotal = item.ten_khoi === 'Tổng cộng'; // Xác định hàng tổng cộng
											const isExpanded = expandedGrades[item.dm_khoi_id]; // Xác định trạng thái mở rộng của khối

											// Hàng chính của từng khối hoặc hàng tổng cộng
											if (
												index === 0 ||
												dataReport[index - 1].dm_khoi_id !== item.dm_khoi_id ||
												isTotal
											) {
												acc.push(
													<tr
														key={`header-${item.dm_khoi_id}`}
														className="expandable-row"
														style={{ backgroundColor: isTotal ? '#f5f5f5' : '' }}
													>
														<td
															colSpan={3}
															style={{
																cursor: isTotal ? 'default' : 'pointer',
																fontWeight: isTotal ? 'bold' : 'normal'
															}}
															onClick={!isTotal ? () => toggleGrade(item.dm_khoi_id) : undefined}
														>
															{!isTotal && <a href='#' className='toggle-grade'>{item.ten_khoi}</a>}
															{isTotal && item.ten_khoi}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
															{calculateTotal(dataReport, item.dm_khoi_id, 0)}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
															{calculateTotal(dataReport, item.dm_khoi_id, 1)}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
															{calculateTotal(dataReport, item.dm_khoi_id, 2)}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
															{calculateTotal(dataReport, item.dm_khoi_id, 3)}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
															{calculateTotal(dataReport, item.dm_khoi_id, 4)}
														</td>
														<td className="text-center" style={{ fontWeight: isTotal ? 'bold' : '500' }}>
														</td>
													</tr>
												);
											}

											// Hàng chi tiết của từng lớp (khi mở rộng)
											if (isExpanded && !isTotal) {
												acc.push(
													<tr key={`details-${index}`}>
														<td
															rowSpan={item.rowspanKhoi}
															hidden={item.rowspanKhoi === 0}
														>
															{item.ten_khoi}
														</td>
														<td
															rowSpan={item.rowspanHe}
															hidden={item.rowspanHe === 0}
														>
															{item.ten_he}
														</td>
														<td>{item.ten_lop}</td>
														<td className="text-center">
															{item.chua_diem_danh > 0 ? (
																<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, 0)}>
																	{item.chua_diem_danh}
																</a>
															) : (
																'-'
															)}
														</td>
														<td className="text-center">
															{item.dung_gio > 0 ? (
																<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, 1)}>
																	{item.dung_gio}
																</a>
															) : (
																'-'
															)}
														</td>
														<td className="text-center">
															{item.den_muon > 0 ? (
																<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, 2)}>
																	{item.den_muon}
																</a>
															) : (
																'-'
															)}
														</td>
														<td className="text-center">
															{item.vang_co_phep > 0 ? (
																<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, 3)}>
																	{item.vang_co_phep}
																</a>
															) : (
																'-'
															)}
														</td>
														<td className="text-center">
															{item.vang_khong_phep > 0 ? (
																<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, 4)}>
																	{item.vang_khong_phep}
																</a>
															) : (
																'-'
															)}
														</td>
														<td className="text-center"
															rowSpan={item.rowspanKhoi}
															hidden={item.rowspanKhoi === 0}
														>
															{item.tong_cong || '-'}
														</td>
													</tr>
												);
											}
											return acc;
										}, [])}
									</tbody>
								</table>

							}
						</div>
					</div>
				</div>
			</div>
			{isShowPopup && <PopupTinhTrangDiemDanhBox trang_thai_diem_danh={trangThai} dm_lop_id={idLop} onClose={onClose} />}
		</>
	);
};

export default TinhTrangDiemDanhBox;

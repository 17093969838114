import expand_right from '../../assets/images/expand-right.svg';
import expand_right2 from '../../assets/images/expand-right2.svg';
import icon_1 from '../../assets/images/icon_1.svg';
import icon_2 from '../../assets/images/icon_2.svg';
import logoutSvg from '../../assets/images/logout.svg';
import teacher from '../../assets/images/teacher.svg';
import teacher2 from '../../assets/images/teacher2.svg';
import union from '../../assets/images/Frame.svg';
import union2 from '../../assets/images/Frame2.svg';
import avt from '../../assets/images/user.svg';
import { useAuth } from '../../utils/AuthProvider';

type ISideBarProps = {
	// isShowSideBarMobile: boolean | false;
	// isShowSideBar: boolean | false;
	// showSideBar: () => void;
	// setShowSidebarMobile: () => void;
};

const Sidebar = (props: ISideBarProps) => {
	const { account, logout } = useAuth();
	// const { isShowSideBar, isShowSideBarMobile, showSideBar, setShowSidebarMobile } = props;
	return (
		<>
			<div className="pushmenu-push">
				<div className="pushmenu pushmenu-left">
					<ul className="col-xs-12 menu-left">
						<li>
							<a href="https://portal.megaedu.net.vn/" title="">
								<img src={expand_right} alt="" />
								<img src={expand_right2} alt="" />
								<span>Trang chủ</span>
							</a>
						</li>
						<li>
							<a href="/">
								<img src={icon_1} alt="" />
								<img src={icon_2} alt="" />
								<span>Tổng quan trường học</span>
							</a>
						</li>
						<li>
							<a href="/diem-danh">
								<img src={union} alt="" />
								<img src={union2} alt="" />
								<span>Dashboard điểm danh</span>
							</a>
						</li>
						<li>
							<a href="/ho-so-hoc-sinh">
								<img src={teacher} alt="" />
								<img src={teacher2} alt="" />
								<span>Dashboard hồ sơ học sinh</span>
							</a>
						</li>
					</ul>
					<div className="col-xs-12 menu-footer">
						<a href="#" id="menuToggle">
							<img src={avt} alt="" />
							<span>Administrator</span>
						</a>
						<a href="#" onClick={() => logout()}><img src={logoutSvg} alt="" /></a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;

import { IActionTypeBase } from '../../../base/IBaseActionTypes';
import { dm_coso } from '../../../models/mega/dm_coso';
import { dm_khoi } from '../../../models/mega/dm_khoi';
import { dm_namhoc } from '../../../models/mega/dm_namhoc';
import { dm_truong } from '../../../models/mega/dm_truong';
import { dm_tuan } from '../../../models/mega/dm_tuan';
import { dm_tu_van_viens } from '../../../models/mega/dm_tuvanvien';
import { tuan_request } from '../../../models/mega/tuan_request';

export enum eCategoryActionTypeIds {
	GET_COSO_REQUEST = 'Category_GET_COSO_REQUEST',
	GET_COSO_SUCCESS = 'Category_GET_COSO_SUCCESS',
	GET_COSO_FAILURE = 'Category_GET_COSO_FAILURE',

	GET_TRUONG_REQUEST = 'Category_GET_TRUONG_REQUEST',
	GET_TRUONG_SUCCESS = 'Category_GET_TRUONG_SUCCESS',
	GET_TRUONG_FAILURE = 'Category_GET_TRUONG_FAILURE',

	GET_KHOI_REQUEST = 'Category_GET_KHOI_REQUEST',
	GET_KHOI_SUCCESS = 'Category_GET_KHOI_SUCCESS',
	GET_KHOI_FAILURE = 'Category_GET_KHOI_FAILURE',

	GET_NAMHOC_REQUEST = 'Category_GET_NAMHOC_REQUEST',
	GET_NAMHOC_SUCCESS = 'Category_GET_NAMHOC_SUCCESS',
	GET_NAMHOC_FAILURE = 'Category_GET_NAMHOC_FAILURE',

	GET_TUVANVIEN_REQUEST = 'Category_GET_TUVANVIEN_REQUEST',
	GET_TUVANVIEN_SUCCESS = 'Category_GET_TUVANVIEN_SUCCESS',
	GET_TUVANVIEN_FAILURE = 'Category_GET_TUVANVIEN_FAILURE',

	GET_TUAN_REQUEST = 'Category_GET_TUAN_REQUEST',
	GET_TUAN_SUCCESS = 'Category_GET_TUAN_SUCCESS',
	GET_TUAN_FAILURE = 'Category_GET_TUAN_FAILURE',
}

export interface CategoryAction_GETCOSO_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_COSO_REQUEST, undefined> { }
export interface CategoryAction_GETCOSO_Success extends IActionTypeBase<eCategoryActionTypeIds.GET_COSO_SUCCESS, dm_coso[]> { }
export interface CategoryAction_GETCOSO_Failure extends IActionTypeBase<eCategoryActionTypeIds.GET_COSO_FAILURE, Error | string> { }

export interface CategoryAction_GETTRUONG_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_TRUONG_REQUEST, undefined> { }
export interface CategoryAction_GETTRUONG_Success extends IActionTypeBase<eCategoryActionTypeIds.GET_TRUONG_SUCCESS, dm_truong[]> { }
export interface CategoryAction_GETTRUONG_Failure extends IActionTypeBase<eCategoryActionTypeIds.GET_TRUONG_FAILURE, Error | string> { }

export interface CategoryAction_GETKHOI_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_KHOI_REQUEST, undefined> { }
export interface CategoryAction_GETKHOI_Success extends IActionTypeBase<eCategoryActionTypeIds.GET_KHOI_SUCCESS, dm_khoi[]> { }
export interface CategoryAction_GETKHOI_Failure extends IActionTypeBase<eCategoryActionTypeIds.GET_KHOI_FAILURE, Error | string> { }

export interface CategoryAction_GETNAMHOC_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_NAMHOC_REQUEST, undefined> { }
export interface CategoryAction_GETNAMHOC_Success extends IActionTypeBase<eCategoryActionTypeIds.GET_NAMHOC_SUCCESS, dm_namhoc[]> { }
export interface CategoryAction_GETNAMHOC_Failure extends IActionTypeBase<eCategoryActionTypeIds.GET_NAMHOC_FAILURE, Error | string> { }

export interface CategoryAction_GETTUVANVIEN_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_TUVANVIEN_REQUEST, undefined> { }
export interface CategoryAction_GETTUVANVIEN_Success
	extends IActionTypeBase<eCategoryActionTypeIds.GET_TUVANVIEN_SUCCESS, dm_tu_van_viens[]> { }
export interface CategoryAction_GETTUVANVIEN_Failure
	extends IActionTypeBase<eCategoryActionTypeIds.GET_TUVANVIEN_FAILURE, Error | string> { }

export interface CategoryAction_GETTUAN_Request extends IActionTypeBase<eCategoryActionTypeIds.GET_TUAN_REQUEST, tuan_request> { }
export interface CategoryAction_GETTUAN_Success extends IActionTypeBase<eCategoryActionTypeIds.GET_TUAN_SUCCESS, dm_tuan[]> { }
export interface CategoryAction_GETTUAN_Failure extends IActionTypeBase<eCategoryActionTypeIds.GET_TUAN_FAILURE, Error | string> { }

export type CategoryActionTypes =
	| CategoryAction_GETCOSO_Request
	| CategoryAction_GETCOSO_Success
	| CategoryAction_GETCOSO_Failure
	| CategoryAction_GETTRUONG_Request
	| CategoryAction_GETTRUONG_Success
	| CategoryAction_GETTRUONG_Failure
	| CategoryAction_GETKHOI_Request
	| CategoryAction_GETKHOI_Success
	| CategoryAction_GETKHOI_Failure
	| CategoryAction_GETNAMHOC_Request
	| CategoryAction_GETNAMHOC_Success
	| CategoryAction_GETNAMHOC_Failure
	| CategoryAction_GETTUVANVIEN_Request
	| CategoryAction_GETTUVANVIEN_Success
	| CategoryAction_GETTUVANVIEN_Failure
	| CategoryAction_GETTUAN_Request
	| CategoryAction_GETTUAN_Success
	| CategoryAction_GETTUAN_Failure
	;

import { useLocation, useNavigate } from 'react-router-dom';
import { AccountInfo } from '../context/models/my-system/accounts';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../context/stores/rootActions';
import { MenuResponse } from '../context/models/my-system/accounts/MenuResponse';
import { AppState } from '../context/stores/rootReducers';
import { eBaseActionStatus } from '../context/base/eBaseActionStatus';

const portalUrl: string = import.meta.env.VITE_APP_PORTAL_URL?.toString() as string;
const subSytemId: string = import.meta.env.VITE_APP_SUB_SYSTEM?.toString() as string;

const ssoRequest = `${portalUrl}/login?service=${window.location.origin}&system_id=${subSytemId}`;

type AuthContextType = {
	account: AccountInfo | null;
	menus: MenuResponse[];
	login: (acount: AccountInfo | null) => void;
	logout: () => void;
};

const AuthContext = createContext<AuthContextType>(null!);

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const AuthProvider = ({ children, account }: { children: any; account: AccountInfo | null }) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState<AccountInfo | null>(account);
	const { accountStatus, loginStatus, loginResponse } = useSelector((state: AppState) => state.account);
	const navigate = useNavigate();
	const query = useQuery();
	const hash = query.get('hash') || '';
	const ticket_key = query.get('key') || '';

	// call this function when you want to authenticate the acount
	const login = (data: AccountInfo | null) => {
		setUser(data);
		navigate('/', { replace: true });
	};

	// call this function to sign out logged in user
	const logout = () => {
		dispatch(actions.account.logoutRequest());
		setUser(null);
		// navigate('/login', { replace: true });
		window.location.href = ssoRequest;
	};

	const value = useMemo(() => {
		return {
			account: user,
			menus: user?.menus || [],
			login,
			logout,
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if(accountStatus == eBaseActionStatus.complete){
			if (!user || user.id <= 0) {
				if (ticket_key != '' && hash != '') {
					dispatch(actions.account.loginRequest({ password: ticket_key, user_name: hash }));
				} else {
					window.location.href = ssoRequest;
				}
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountStatus, user, ticket_key, hash]);

	useEffect(() => {
		if (loginStatus === eBaseActionStatus.complete && loginResponse) login(loginResponse?.account_info || null);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
	return context;
};

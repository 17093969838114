import React from 'react'
import { useSelector } from 'react-redux';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxFont,
	DxFormat,
	DxLabel,
	DxLegend,
	DxSeries,
	DxTitle
} from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';
import { AppState } from '../../../context/stores/rootReducers';
import { useLookupTruong } from '../../../components/shared/mega/combobox-truong/useLookupTruong';

interface dataReportType {
	id: number;
	ma_he: string;
	ten_he: string;
	ten_he_en: string;
	dm_truong_id: number;
	dm_coso_id: number;
	dm_he_id: number;
	dm_khoi_id: number;
	ma_khoi: string;
	ten_khoi: string;
	ten_khoi_en: string;
	hoc_sinh: number;
	ten_truong: string;
	ten_truong_en: string;
}

const customizeText = (arg: any) => {
	return `${arg.valueText} (${arg.percentText})`;
};
const GiaoVienTheoTrinhDo = () => {
	const { dm_coso_id, dm_truong_id } = useSelector((state: AppState) => state.common);
	const { dm_truong_all } = useLookupTruong();
	const dataSource = React.useMemo(() => {
		return dm_truong_all
			?.filter((x) => x.dm_coso_id === dm_coso_id)
			?.map((x) => ({
				id: x.id,
				ten_truong: x.ten_truong,
				tot: 0,
				kha: 0,
				trung_binh: 0,
				yeu: 0,
			}));
	}, [dm_truong_all, dm_coso_id]);
	return (
		<>
			<div className='row g-gs justify-content-center'>
				{dataSource.map((khoi, idx) => {
					const soLieuTheoKhois = [
						{
							title: 'Trên Đại học',
							total: 0,
						},
						{
							title: 'Đại học',
							total: 0,
						},
						{
							title: 'Cao đẳng',
							total: 0,
						},
						{
							title: 'Trung cấp',
							total: 0,
						},
					];
					return (
						<div className='col-md-6 col-xxl-6' key={idx}>
							<div className='card h-100'>
								<div className='card-inner'>
									<DxChart id={createUUID()} dataSource={soLieuTheoKhois} className='w-100' palette={'Dark Moon'} height={220}>
										<DxTitle text={khoi.ten_truong}>
											<DxFont color='black' size={12} weight={600} />
										</DxTitle>
										<DxCommonSeriesSettings
											argumentField='title'
											type='bar'
											hoverMode='allArgumentPoints'
											selectionMode='allArgumentPoints'
										>
											<DxLabel visible={true}>
												<DxFormat type='fixedPoint' precision={0} />
											</DxLabel>
										</DxCommonSeriesSettings>
										<DxSeries valueField='total' argumentField='title' name='Trình độ' type='bar'></DxSeries>
										<DxArgumentAxis>
											<DxLabel rotationAngle={45} overlappingBehavior='rotate' />
										</DxArgumentAxis>
										<DxLegend orientation='horizontal' verticalAlignment='bottom' horizontalAlignment='center' visible={false}/>
									</DxChart>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default GiaoVienTheoTrinhDo;

export const Loadingv2 = () => {
	return (
		<>
			<div className="box-loading">
				<div id="loading-spinner" className="spinner">
					<div className="dot-1">
						<div className="dot"></div>
						<div className="dot"></div>
					</div>
					<div className="dot-2">
						<div className="dot"></div>
						<div className="dot"></div>
					</div>
				</div>
				<p className="loading">Loading...</p>
			</div>
		</>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { DxConnectorPieChart, DxLabelPieChart, DxLegendPieChart, DxPieChart, DxSeriesPieChart } from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';
import moment from 'moment';

interface ChiTietProps {
	idHocSinh: string,
	nam_hoc: string
}

interface BaoCaoDiemDanhHocSinhResponse {
	// chua_diem_danh: number,
	dung_gio: number,
	den_muon: number,
	vang_co_phep: number,
	vang_khong_phep: number
}

const ChiTietThongTinHocSinhTab_DiemDanh = (props: ChiTietProps) => {
	const [dataReport, setDataReport] = useState<BaoCaoDiemDanhHocSinhResponse>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [tongCong, setTongCong] = useState(0);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.hoSo.SelectDiemDanhHocSinhAsync(props.idHocSinh);
		if (res.isSuccess && res.result) {
			const baoCaoRes = res.result as BaoCaoDiemDanhHocSinhResponse;
			setDataReport(baoCaoRes)

		} else {
			setDataReport(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [props.idHocSinh]);


	const khoiGrouped = useMemo(() => {
		const huyenGrouped = [];
		if (dataReport) {
			// huyenGrouped.push({ ten_loai: 'Chưa điểm danh', so_luong: dataReport.chua_diem_danh })
			huyenGrouped.push({ ten_loai: 'Đúng giờ', so_luong: dataReport.dung_gio })
			huyenGrouped.push({ ten_loai: 'Đến muộn', so_luong: dataReport.den_muon })
			huyenGrouped.push({ ten_loai: 'Vắng có phép', so_luong: dataReport.vang_co_phep })
			huyenGrouped.push({ ten_loai: 'Vắng không phép', so_luong: dataReport.vang_khong_phep })
			setTongCong(dataReport.dung_gio + dataReport.den_muon + dataReport.vang_co_phep + dataReport.vang_khong_phep)
		}
		return huyenGrouped;
	}, [dataReport]);

	useEffect(() => {
		handleReloadData();
	}, [props.idHocSinh]);

	const customizeText = (arg: any) => {
		return `${arg.argumentText}: ${arg.valueText}`;
	};

	return (
		<>
			<div className="title" style={{fontSize: '18px'}}>Lịch sử điểm danh</div>
			<div className="col-xs-12 chart-placeholder">
				{dataReport &&
					<DxPieChart
						id={createUUID()}
						palette={['#1E8FF5', '#26CECB', '#8037ED', '#A6F6FF', '#001859']}
						dataSource={khoiGrouped}
						resolveLabelOverlapping={'shift'}
						className='w-100'
						height={400}
					>
						<DxLegendPieChart
							orientation='horizontal'
							itemTextPosition='right'
							horizontalAlignment='center'
							verticalAlignment='bottom'
							columnCount={4}
						/>
						<DxSeriesPieChart argumentField='ten_loai' valueField='so_luong'>
							<DxLabelPieChart visible={true} position='columns' customizeText={customizeText}>
								<DxConnectorPieChart visible={true} width={0.5} />
							</DxLabelPieChart>
						</DxSeriesPieChart>
					</DxPieChart>
				}
			</div>
			<div className="col-xs-12 flex-box">
				<div className="col-sm-4" style={{fontSize: '16px'}}>Tổng số: <b>{tongCong}</b> ngày</div>
				<div className="col-sm-8" style={{fontSize: '14px', fontFamily:'Arial, sans-serif', fontStyle:'italic'}}>Dữ liệu được tổng hợp đến: <b>{moment().format('DD/MM/YYYY')}</b></div>
			</div>
		</>
	);
};

export default ChiTietThongTinHocSinhTab_DiemDanh;

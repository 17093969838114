/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useSelector } from 'react-redux';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';
import { HocSinhBaoCaoTongQuanResponse } from '../../../context/models/mega/HocSinhBaoCaoTongQuanResponse';
import BaoCaoHocSinhPieChart from '../baocao-theokhoi/BaoCaoHocSinhPieChart';
import BaoCaoHocSinhTheoHePieChart from './BaoCaoHocSinhKetQuaHocTapPieChart';
import BaoCaoSucKhoeHocSinhChart from '../baocao-theokhoi/BaoCaoSucKhoeHocSinhChart';
import BaoCaoHocSinhTheoKhoi from '../baocao-theokhoi/BaoCaoHocSinhTheoKhoi';
import { Loading } from '../../../components/base';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import BaoCaoHocSinhKetQuaHocTapPieChart from './BaoCaoHocSinhKetQuaHocTapPieChart';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import PopupKetQuaHocTapBox from './PopupKetQuaHocTapBox';
import { NoDataLoadingKqht } from '../../../components/base/NoDataLoadingKqht';
import DataTableKetQuaHocTap from './DataTableKetQuaHocTap';

interface BaoCaoHocSinhKqhtTableResponse {
	dm_xep_hangs: string[],
	dm_hoc_sinh_xep_hangs: HocSinhKqhtChiTietResponse[]
}

interface HocSinhKqhtChiTietResponse {
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	ten_lop: string;
	ten_lop_en: string;
	rowspanKhoi: number;
	rowspanHe: number;
	so_luongs: number[];
	dm_lop_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
}

const BaoCaoHocSinhKetQuaHocTapBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhKqhtTableResponse>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableKetQuaHocTapAsync(dm_coso_id, dm_truong_id, 0, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhKqhtTableResponse;
			setDataReport(listCategory);
		} else {
			setDataReport(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	return (
		<>
			{/* <!-- Tổng số học sinh box (tỉ lệ 1) --> */}
			<div className="box box-1">
				<BaoCaoHocSinhKetQuaHocTapPieChart />
			</div>
			{/* <!-- Biểu đồ box (tỉ lệ 2) --> */}
			<div className="box box-2 no-margin">
				{!isLoadingTableTheoHe && dataReport?.dm_xep_hangs &&
					<>
						<div className="title">Chi tiết xếp loại danh hiệu học sinh</div>
					</>
				}
				{!isLoadingTableTheoHe && !dataReport?.dm_xep_hangs && <NoDataLoadingKqht />}
				<div className="table-container">
					{isLoadingTableTheoHe &&
						<Loadingv2 />
					}
					{!isLoadingTableTheoHe && dataReport?.dm_xep_hangs &&
						<>
							<DataTableKetQuaHocTap dataReport={dataReport} />
						</>
					}
				</div>
			</div>
		</>
	);
};

export default BaoCaoHocSinhKetQuaHocTapBox;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { jwtTokenHelper } from '../../../../utils/jwtTokenHelper';
import { rootApi } from '../../../api/rootApi';
import { BaseResponse } from '../../../base/BaseResponse';
import { AccountInfo, LoginResponse } from '../../../models/my-system/accounts';
import { actions } from '../../rootActions';
import { ChangePassword_Request_Action, Login_Request_Action, eAccountActionTypeIds } from './IAccountActionsTypes';

function* onLoginRequest(action: Login_Request_Action) {
	try {
		const res: BaseResponse = yield call(rootApi.account.Login, action.payload);
		if (res.isSuccess && res.result) {
			const loginResponse = res.result as LoginResponse;
			yield put(actions.account.loginSuccess(loginResponse));
			yield put(actions.account.getAccountSuccess(loginResponse?.account_info ?? null));
			jwtTokenHelper.SET(`${loginResponse.jwt_token} ${loginResponse.refresh_token}`);
		} else {
			yield put(actions.account.loginFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.account.loginFailure(error || 'Có lỗi'));
	}
}

function* onLogoutRequest() {
	yield put(actions.account.logoutSuccess());
	jwtTokenHelper.CLEAR();
}

function* onAccountRequest() {
	try {
		if (jwtTokenHelper.GET()) {
			const res: BaseResponse = yield call(rootApi.account.Detail);
			if (res.isSuccess && res.result) {
				const accountInfo = res.result as AccountInfo;
				yield put(actions.account.getAccountSuccess(accountInfo));
			} else {
				yield put(actions.account.getAccountFailure(res.message));
			}
		} else {
			yield put(actions.account.getAccountFailure('Tài khoản không hợp lệ'));
		}
	} catch (error: any) {
		yield put(actions.account.getAccountFailure(error || 'Có lỗi'));
	}
}

function* onChangePassword(action: ChangePassword_Request_Action) {
	try {
		const res: BaseResponse = yield call(rootApi.account.ChangPassword, action.payload);
		if (res.isSuccess && res.result) {
			yield put(actions.account.changePasswordSuccess(res.message));
		} else {
			yield put(actions.account.changePasswordFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.account.changePasswordFailure(error || 'Có lỗi'));
	}
}

function* watchOnAccountRequest() {
	yield takeEvery(eAccountActionTypeIds.GET_ACCOUNT_REQUEST, onAccountRequest);
}

function* watchOnChangePassword() {
	yield takeEvery(eAccountActionTypeIds.CHANGE_PASSWORD_REQUEST, onChangePassword);
}

function* watchOnLoginRequest() {
	yield takeEvery(eAccountActionTypeIds.LOGIN_REQUEST, onLoginRequest);
}

function* watchOnLogoutRequest() {
	yield takeEvery(eAccountActionTypeIds.LOGOUT, onLogoutRequest);
}

function* accountSaga() {
	yield all([fork(watchOnLoginRequest)]);
	yield all([fork(watchOnLogoutRequest)]);
	yield all([fork(watchOnAccountRequest)]);
	yield all([fork(watchOnChangePassword)]);
}

export default accountSaga;

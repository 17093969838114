/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import imgButton from '../../../assets/images/button.svg';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	trang_thai: number;
	giao_vien_phu_trach: string;
	gio_diem_danh: string;

}

interface PopUpDiemDanhNgayProps {
	trang_thai_diem_danh: number,
	onClose: () => void;
}

const PopupBaoCaoDiemDanhNgayBox = (props: PopUpDiemDanhNgayProps) => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectDanhSachLopDiemDanhPopupAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay, props.trang_thai_diem_danh);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	// Hàm xuất file Excel
	const handleExportExcel = () => {
		if (dataReport.length === 0) {
			alert('Không có dữ liệu để xuất.');
			return;
		}

		// Lấy thời gian hiện tại để đặt vào tên file
		const now = new Date().toISOString().replace(/[-T:\.Z]/g, '_');
		const fileName = `BaoCaoDiemDanh_${now}.xlsx`;

		// Chuẩn bị dữ liệu để xuất ra, bao gồm cột Số thứ tự
		const dataToExport = dataReport.map((item, index) => ({
			'STT': index + 1, // Số thứ tự
			'Khối': item.ten_khoi,
			'Hệ': item.ten_he,
			'Lớp': item.ten_lop,
			'Trạng thái': item.trang_thai === 0 ? 'Chưa điểm danh' : 'Đã điểm danh',
			'Giáo viên phụ trách': item.giao_vien_phu_trach,
			'Giờ điểm danh': item.gio_diem_danh,
		}));

		// Tạo worksheet từ dữ liệu
		const worksheet = XLSX.utils.json_to_sheet(dataToExport);

		// Đặt kích thước cột (bao gồm cột STT)
		worksheet['!cols'] = [
			{ wpx: 50 },  // STT
			{ wpx: 150 }, // Khối
			{ wpx: 150 }, // Hệ
			{ wpx: 150 }, // Lớp
			{ wpx: 150 }, // Trạng thái
			{ wpx: 200 }, // Giáo viên phụ trách
			{ wpx: 120 }, // Giờ điểm danh
		];

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách điểm danh');

		// Xuất file Excel
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
		saveAs(file, fileName); // Lưu file với tên chứa ngày giờ
	};

	return (
		<>
			{/* <!-- chi tiet diem danh --> */}
			<div className="modal diemdanh-detail show" id="myModal" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="col-xs-12 modal-content">
						<div className="col-xs-12 modal-header">
							<h4 className="modal-title">Chi tiết điểm danh lớp học</h4>
							<button type="button" className="close" onClick={props.onClose}><img src={imgButton} /></button>
						</div>
						<div className="col-xs-12 modal-body">
							<button className="btn btn-primary" onClick={handleExportExcel} style={{ float: 'right' }}>
								Export Excel
							</button>
							<div className="col-xs-12 table-container">
								{isLoadingTableTheoHe &&
									<Loadingv2 />
								}
								{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
									<table id="attendanceTable">
										<thead>
											<tr>
												<th>Khối</th>
												<th>Hệ</th>
												<th className="text-center">Lớp</th>
												<th>Trạng thái</th>
												<th>Giáo viên phụ trách</th>
												<th>Giờ điểm danh</th>
											</tr>
										</thead>
										<tbody>
											{dataReport.map((item) =>
												<tr className="expandable-row">
													<td rowSpan={item.rowspanKhoi} hidden={item.rowspanKhoi === 0}>{item.ten_khoi}</td>
													<td rowSpan={item.rowspanHe} hidden={item.rowspanHe === 0}>{item.ten_he}</td>
													<td>{item.ten_lop}</td>
													{item.trang_thai === 0 && <td className="text-center not-attendance">Chưa điểm danh</td>}
													{item.trang_thai === 1 && <td className="text-center attendance">Đã điểm danh</td>}
													<td className="text-center">{item.giao_vien_phu_trach}</td>
													<td className="text-center">{item.gio_diem_danh}</td>
												</tr>
											)}
										</tbody>
									</table>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- end chi tiet diem danh --> */}
		</>
	);
};

export default PopupBaoCaoDiemDanhNgayBox;

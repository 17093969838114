import no_document_icon from '../../../assets/images/no_document.png'
export const NoDataLoading = () => {
	return (
		<>
			<div className="box-loading" style={{margin : 0}}>
				<div id="loading-spinner" className="spinner">
					<img src={no_document_icon} width={32}/>
				</div>
				<p>Dữ liệu trống</p>
			</div>
		</>
	);
};

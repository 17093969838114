import { ApiClient } from "../../base/_ApiClient";

export const hoSoApi = {
	SelectDanhSachKhoiAsync: (dm_truong_id: number) => {
		return ApiClient.GET(`/ho-so/khoi/by-truong/${dm_truong_id}`);
	},
	SelectDanhSachHeAsync: (dm_truong_id: number, dm_khoi_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/ho-so/he/by-truong/${dm_truong_id}/khoi/${dm_khoi_id}/nam-hoc/${nam_hoc}`);
	},
	SelectDanhSachLopAsync: (dm_truong_id: number, dm_khoi_id: number, dm_he_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/ho-so/lop/by-truong/${dm_truong_id}/khoi/${dm_khoi_id}/he/${dm_he_id}/nam-hoc/${nam_hoc}`);
	},
	SelectThongTinHocSinhAsync: (idHocSinh: string, nam_hoc: string) => {
		return ApiClient.GET(`/ho-so/thong-tin-hoc-sinh/${idHocSinh}/nam-hoc/${nam_hoc}`);
	},
	SelectQuanHeHocSinhAsync: (idHocSinh: string) => {
		return ApiClient.GET(`/ho-so/quan-he-hoc-sinh/${idHocSinh}`);
	},
	SelectDiemDanhHocSinhAsync: (idHocSinh: string) => {
		return ApiClient.GET(`/ho-so/diem-danh-hoc-sinh/${idHocSinh}`);
	},
	SelectThoiKhoaBieuHocSinhAsync: (idHocSinh: string, nam_hoc: string, tuan: number) => {
		return ApiClient.GET(`/ho-so/thoi-khoa-bieu-hoc-sinh/${idHocSinh}/nam-hoc/${nam_hoc}/tuan/${tuan}`);
	},
	SelectDanhSachHocSinhAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, dm_khoi_id: number, dm_he_id: number, dm_lop_id: number, ten_hoc_sinh: string, ma_hoc_sinh: string, trang_thai: number) => {
		return ApiClient.GET(`/ho-so/hoc-sinh/by-coso/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/khoi/${dm_khoi_id}/he/${dm_he_id}/lop/${dm_lop_id}/ten-hoc-sinh/${ten_hoc_sinh === '' ? 'null' : ten_hoc_sinh}/ma-hoc-sinh/${ma_hoc_sinh === '' ? 'null' : ma_hoc_sinh}/trang-thai/${trang_thai}`);
	},
	SelectKetQuaHocTapAsync: (idHocSinh: string) => {
		return ApiClient.GET(`/ho-so/ket-qua-hoc-tap/${idHocSinh}`);
	},
	SelectKetQuaHocTapChiTietAsync: (idHocSinh: string, nam_hoc: string) => {
		return ApiClient.GET(`/ho-so/ket-qua-hoc-tap-chi-tiet/${idHocSinh}/nam-hoc/${nam_hoc}`);
	},
	SelectDanhSachTrangThaiAsync: (dm_coso_id: number, dm_truong_id: number) => {
		return ApiClient.GET(`/ho-so/trang-thai/by-coso/${dm_coso_id}/truong/${dm_truong_id}`);
	},
};

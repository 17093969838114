export class IBaseActionRespone<X, Y> {
	type: X;
	payload: Y;
	constructor(type: X, payload: Y) {
		this.type = type;
		this.payload = payload;
	}
}

export function BaseAction<X, Y>(type: X, input: Y) {
	return { ...new IBaseActionRespone(type, input) };
}

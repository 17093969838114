import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';

export const SelectBoxTuan = () => {
	const { tuan } = useSelector((state: AppState) => state.common);
	const dispatch = useDispatch();
	const { nam_hoc, dm_truong_id } = useSelector((state: AppState) => state.common);
	const { dm_tuan_all } = useSelector((state: AppState) => state.mega.category);
	useEffect(() => {
		if (dm_tuan_all.length === 0)
			dispatch(actions.mega.category.getTUANRequest({ dm_truong_id: dm_truong_id, nam_hoc: nam_hoc }));
	}, [dm_truong_id, nam_hoc]);

	useEffect(() => {
		if (dm_tuan_all.length > 0) {
			dispatch(actions.common.changeTuan(dm_tuan_all.find(x => x.is_default === true)?.tuan_thu ?? 0))
		}
	}, [dm_tuan_all]);

	return (
		<DxSelectBox
			dataSource={dm_tuan_all}
			value={tuan}
			//    width={200}
			onValueChanged={(value) => {
				dispatch(actions.common.changeTuan(value || 0));
			}}
			valueExpr='tuan_thu'
			displayExpr='tuan_text'
			placeholder='Chọn tuần'
			name={`tuan_${createUUID()}`}
		/>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import imgDiemDanh1 from '../../../assets/images/imgDiemDanh1.svg';
import imgDiemDanh2 from '../../../assets/images/imgDiemDanh2.svg';
import imgDiemDanh3 from '../../../assets/images/imgDiemDanh3.svg';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { DiemDanhResponse } from '../../../context/models/mega/DiemDanhResponse';
import { AppState } from '../../../context/stores/rootReducers';
import PopupBaoCaoDiemDanhNgayBox from './PopupBaoCaoDiemDanhNgayBox';

const BaoCaoDiemDanhNgayBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);

	const [tongSoLop, setTongSoLop] = useState(0);
	const [tongSoLopChuaDiemDanh, setTongSoLopChuaDiemDanh] = useState(0);
	const [isShowPopup, setShowPopup] = useState(false);
	const [trangThaiDiemDanh, setTrangThaiDiemDanh] = useState(0);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectBaoCaoDiemDanhTheoNgayAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as DiemDanhResponse[];
			setTongSoLop(listCategory.length);
			setTongSoLopChuaDiemDanh(listCategory.filter(x => x.chua_diem_danh > 0).length);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc, tu_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, dm_truong_id, nam_hoc, tu_ngay]);

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopupTongSo = () => {
		setShowPopup(true)
		setTrangThaiDiemDanh(-1)

	}

	const onOpenPopupDaDiemDanh = () => {
		setShowPopup(true)
		setTrangThaiDiemDanh(1)

	}

	const onOpenPopupChuaDiemDanh = () => {
		setShowPopup(true)
		setTrangThaiDiemDanh(0)

	}

	return (
		<>
			<div className="col-xs-12 box-content box diemdanh">
				<div className="col-sm-4 col-xs-12 box-total">
					<div className="box-img"><img src={imgDiemDanh1} /></div>
					<div className="box-detail">
						<p>Tổng số lớp</p>
						<p></p>
						{tu_ngay === den_ngay && <p><a style={{ color: '#3555f2' }} href='#' onClick={onOpenPopupTongSo}>{tongSoLop}</a></p>}
						{tu_ngay !== den_ngay && <p style={{ color: 'black' }}>{tongSoLop}</p>}
					</div>
				</div>
				<div className="col-sm-4 col-xs-12 box-total">
					<div className="box-img"><img src={imgDiemDanh2} /></div>
					<div className="box-detail">
						<p>Lớp đã điểm danh</p>
						{tu_ngay === den_ngay && <p><a style={{ color: '#3555f2' }} href='#' onClick={onOpenPopupDaDiemDanh}>{tongSoLop - tongSoLopChuaDiemDanh}</a></p>}
						{tu_ngay !== den_ngay && <p style={{ color: 'black' }}>-</p>}
					</div>
				</div>
				<div className="col-sm-4 col-xs-12 box-total">
					<div className="box-img"><img src={imgDiemDanh3} /></div>
					<div className="box-detail">
						<p>Lớp chưa điểm danh</p>
						{tu_ngay === den_ngay && <p><a style={{ color: '#3555f2' }} href='#' onClick={onOpenPopupChuaDiemDanh}>{tongSoLopChuaDiemDanh}</a></p>}
						{tu_ngay !== den_ngay && <p style={{ color: 'black' }}>-</p>}
					</div>

				</div>
			</div>
			{tu_ngay !== den_ngay &&
				<div
					style={{ fontStyle: 'italic', position: 'relative', top: '-25px', right:'10px', textAlign:'right' }}>
					Dữ liệu điểm danh lớp chỉ thống kê theo từng ngày
				</div>
			}
			{isShowPopup && <PopupBaoCaoDiemDanhNgayBox trang_thai_diem_danh={trangThaiDiemDanh} onClose={onClose} />}

		</>
	);
};

export default BaoCaoDiemDanhNgayBox;

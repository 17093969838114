import { Modal } from "../../components/base";
import HeaderDiemDanh from "../../layouts/admin/HeaderDiemDanh";
import BaoCaoDiemDanhNgayBox from "./bao-cao-diem-danh-ngay/BaoCaoDiemDanhNgayBox";
import PopupBaoCaoDiemDanhNgayBox from "./bao-cao-diem-danh-ngay/PopupBaoCaoDiemDanhNgayBox";
import ChartTinhTrangDiemDanhChart from "./chart-tinh-trang-diem-danh/ChartTinhTrangDiemDanh";
import ChartXuLyVangMat from "./chart-tinh-trang-diem-danh/ChartXuLyVangMat";
import TinhTrangDiemDanhBox from "./tinh-trang-diem-danh/TinhTrangDiemDanhBox";

const DashboardDiemDanhPage = () => {
	return (
		<>
			<div className="col-xs-12 header-container">
				<div className="col-xs-12 frame-box">
					<HeaderDiemDanh />
				</div>

				{/* <!-- Module điểm danh ngày --> */}
				<BaoCaoDiemDanhNgayBox />
				<div className="col-xs-12 bg-frame">
					<div className="col-xs-12 box-content">
						<ChartTinhTrangDiemDanhChart />
						<ChartXuLyVangMat />
					</div>
				</div>
				{/* <!-- Module tình trạng điểm danh --> */}
				<TinhTrangDiemDanhBox />
				{/* <PopupBaoCaoDiemDanhNgayBox /> */}
			</div>
		</>
	);
};

export default DashboardDiemDanhPage;

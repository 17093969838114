import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DxConnectorPieChart,
	DxFontPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart,
	DxTitlePieChart,
} from '../../../components/common/DxCharts';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import { NoDataLoadingKqht } from '../../../components/base/NoDataLoadingKqht';

interface BaoCaoHocSinhKqhtResponse {
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_lop: string;
	ten_lop_en: string;
	so_luong: number;
	ten_xep_hang_danhhieu: string;
}

const customizeText = (arg: any) => {
	return `${arg.argumentText}: ${arg.percentText} <br/>${arg.valueText} học sinh`;
};
const BaoCaoHocSinhKetQuaHocTapPieChart = () => {
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhKqhtResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectChartKetQuaHocTapAsync(dm_coso_id, dm_truong_id, 0, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhKqhtResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, dm_truong_id, nam_hoc]);
	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: any[], source) => {
			const exited = khoiGroupData.find((x: any) => x.ten_xep_hang_danhhieu === source.ten_xep_hang_danhhieu);
			if (!exited) {
				source = {
					...source,
					so_luong: dataReport.filter(x => x.ten_xep_hang_danhhieu === source.ten_xep_hang_danhhieu).reduce(function (prev, current) {
						return prev + +current.so_luong
					}, 0)
				}
				khoiGroupData.push(source);
			}
			return khoiGroupData; ``
		}, []);
		return huyenGrouped;
	}, [dataReport]);
	
	return (
		<>
			{(!khoiGrouped || khoiGrouped.length === 0) && <NoDataLoadingKqht />}
			{khoiGrouped && khoiGrouped.length > 0 &&
				<DxPieChart
					id={createUUID()}
					palette={['#1E8FF5', '#26CECB', '#8037ED', '#001859', '#A6F6FF']}
					dataSource={khoiGrouped}
					resolveLabelOverlapping={'shift'}
					className='w-100'
					height={400}
				>
					<DxLegendPieChart
						orientation='horizontal'
						itemTextPosition='right'
						horizontalAlignment='center'
						verticalAlignment='bottom'
						columnCount={4}
					/>
					<DxSeriesPieChart argumentField='ten_xep_hang_danhhieu' valueField='so_luong'>
						<DxLabelPieChart visible={true} position='columns' customizeText={customizeText}>
							<DxConnectorPieChart visible={true} width={0.5} />
						</DxLabelPieChart>
					</DxSeriesPieChart>
				</DxPieChart>
			}
		</>
	);
};

export default BaoCaoHocSinhKetQuaHocTapPieChart;

import { ApiClient } from '../../base/_ApiClient';
import { tuan_request } from '../../models/mega/tuan_request';

export const categoryApi = {
	SelectCoSoAsync: () => {
		return ApiClient.GET(`/category/co-so`);
	},
	SelectTruongAsync: () => {
		return ApiClient.GET('/category/truong');
	},
	SelectKhoiAsync: () => {
		return ApiClient.GET('/category/khoi');
	},
	SelectNamHocAsync: () => {
		return ApiClient.GET('/category/nam-hoc');
	},
	SelectTuVanViencAsync: (dm_coso_id?: number) => {
		return ApiClient.GET('/user/nam-hoc', { dm_coso_id });
	},
	SelectTuanAsync: (rq: tuan_request) => {
		return ApiClient.GET(`/category/tuan/${rq.dm_truong_id}/${rq.nam_hoc}`);
	},
};

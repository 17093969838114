import moment from 'moment';
import { useState } from 'react';
import { DxDateBox } from '../../components/base';
import BaoCaoDiemDanhToanTruongPieChart from './baocao-diemdanh/BaoCaoDiemDanhToanTruongPieChart';
import ThongKeDiemDanhNgayLine from './baocao-diemdanh/ThongKeDiemDanhNgayLine';
import BaoCaoDiemDanhKhoiPieChart from './baocao-diemdanh/BaoCaoDiemDanhKhoiPieChart';

const BaoCaoDiemDanhPage = () => {
	const [ngayDiemDanh, setNgayDiemDanh] = useState(moment().format('yyyy-MM-DD'));
	return (
		<>
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-4 col-xxl-4'>
						<div className='card card-full h-100'>
							<div className='card-inner-group'>
								<div className='card-inner'>
									<div className='card-title-group'>
										<div className='card-title card-title-sm'>
											<h6 className='title'>Điểm danh ngày</h6>
										</div>
										<div className='card-tools'>
											<DxDateBox
												name='filter_date'
												onValueChanged={(e) => {
													setNgayDiemDanh(moment(e).format('yyyy-MM-DD'));
												}}
												placeholder='Chọn ngày điểm danh'
												value={ngayDiemDanh}
											/>
										</div>
									</div>
									<div className='pt-2'>
										<BaoCaoDiemDanhToanTruongPieChart ngay_diem_danh={ngayDiemDanh} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-8 col-xxl-8'>
						<div className='card card-full h-100'>
							<div className='card-inner-group'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê điểm danh ngày</h6>
									</div>
									<div className='pt-2'>
										<ThongKeDiemDanhNgayLine ngay_diem_danh={ngayDiemDanh} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner-group'>
								<div className='card-inner'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Thống kê điểm danh ngày theo khối</h6>
									</div>
									<div className='pt-2'>
										<BaoCaoDiemDanhKhoiPieChart ngay_diem_danh={ngayDiemDanh} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaoCaoDiemDanhPage;

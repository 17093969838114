import { ApiClient } from "../../base/_ApiClient";

export const baoCaoApi = {
	SelectTongAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/tong`);
	},
	SelectDiemDanhAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, ngay_diem_danh: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/ngay/${ngay_diem_danh}/diem-danh`);
	},
	GetBaoCaoKhoiHeLopAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/khoi-he-lop`);
	},
	GetBaoCaoLopTheoKhoiAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/lop-theo-khoi`);
	},
	GetBaoCaoNhanSuAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/nhan-su`);
	},
	GetBaoCaoHocSinhTheoKhoiAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/hocsinh-theo-khoi`);
	},
	GetBaoCaoHocSinhTheoHeAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/hocsinh-theo-he`);
	},
	SelectTuyenDuongAsync: (dm_coso_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/bus-tuyen-duong`);
	},
	SelectBaoCaoDiemDanhAsync: (dm_coso_id: number, nam_hoc: string, bus_tuyenduong_id: number, ngay_diem_danh: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/nam-hoc/${nam_hoc}/bus-tuyenduong/${bus_tuyenduong_id}/ngay-diem-danh/${ngay_diem_danh}/bao-cao-diem-danh`);
	},
	ReportLead: (dm_coso_id: number, tu_van_vien_id: number, from_date: string, to_date: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/tu-van-vien/${tu_van_vien_id}/from-date/${from_date}/to-date/${to_date}/report-lead`);
	},
	SelectTuVanVien: (dm_coso_id: number) => {
		return ApiClient.GET(`/bao-cao/tu-van-vien/${dm_coso_id}`);
	},
	SelectTongQuanAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/tong-quan`);
	},
	SelectBienDongHocSinhThangAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/bien-dong-hoc-sinh`);
	},
	SelectChartHocSinhTheoHeAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/theo-he-dao-tao`);
	},
	SelectTableHocSinhTheoHeAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/table-theo-he-dao-tao`);
	},
	SelectPopupHocSinhTheoHeAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number, dm_lop_id: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/lop/${dm_lop_id}/popup-theo-he-dao-tao`);
	},
	SelectChartKetQuaHocTapAsync: (dm_coso_id: number, dm_truong_id: number, dm_khoi_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/khoi/${dm_khoi_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/chart-ket-qua-hoc-tap`);
	},
	SelectTableKetQuaHocTapAsync: (dm_coso_id: number, dm_truong_id: number, dm_khoi_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/khoi/${dm_khoi_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/table-ket-qua-hoc-tap`);
	},
	SelectPopupKetQuaHocTapAsync: (dm_coso_id: number, dm_truong_id: number, dm_khoi_id: number, nam_hoc: string, hoc_ky: number, dm_lop_id: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/khoi/${dm_khoi_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/lop/${dm_lop_id}/popup-ket-qua-hoc-tap`);
	},
	SelectChartNghiHocBaoLuuAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/nghi-hoc-bao-luu`);
	},
	SelectTableNghiHocBaoLuuAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, hoc_ky: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/hoc-ky/${hoc_ky}/table-nghi-hoc-bao-luu`);
	},
	SelectPopupNghiHocBaoLuuAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, dm_he_id: number, dm_trangthaihocsinh_id: number, dm_khoi_id: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong-hoc/${dm_truong_id}/nam-hoc/${nam_hoc}/khoi/${dm_khoi_id}/he/${dm_he_id}/trang-thai/${dm_trangthaihocsinh_id}/popup-nghi-hoc-bao-luu`);
	},
	SelectBaoCaoDiemDanhTheoNgayAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, ngay_diem_danh: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/ngay/${ngay_diem_danh}/bao-cao-diem-danh-theo-ngay`);
	},
	SelectTableTinhTrangDiemDanhAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/table-tinh-trang-diem-danh`);
	},
	SelectChartTinhTrangDiemDanhAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/chart-tinh-trang-diem-danh`);
	},
	SelectChartXuLyVangKhongPhepAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/chart-xu-ly-vang-khong-phep`);
	},
	SelectDanhSachLopDiemDanhPopupAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string, trang_thai: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/trang-thai/${trang_thai}/popup-diem-danh-ngay`);
	},
	SelectPopupXuLyVangKhongPhepAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/popup-xu-ly-vang-khong-phep`);
	},
	SelectDanhSachHocSinhDiemDanhPopupAsync: (dm_coso_id: number, dm_truong_id: number, nam_hoc: string, tu_ngay: string, den_ngay: string, trang_thai: number, dm_lop_id: number) => {
		return ApiClient.GET(`/bao-cao/co-so/${dm_coso_id}/truong/${dm_truong_id}/nam-hoc/${nam_hoc}/tu-ngay/${tu_ngay}/den-ngay/${den_ngay}/trang-thai/${trang_thai}/lop/${dm_lop_id}/popup-tinh-trang-diem-danh`);
	},
};

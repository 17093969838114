/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useEffect, useState } from 'react';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';

import moment from 'moment';
import diachiIcon from '../../../assets/images/diachi.svg';
import dienthoaiIcon from '../../../assets/images/dienthoai.svg';
import emailIcon from '../../../assets/images/email.svg';
import gioitinhIcon from '../../../assets/images/gioitinh.svg';
import heIcon from '../../../assets/images/he.svg';
import khoiIcon from '../../../assets/images/khoi.svg';
import lopIcon from '../../../assets/images/lop.svg';
import madinhdanhIcon from '../../../assets/images/madinhdanh.svg';
import ngaysinhIcon from '../../../assets/images/ngaysinh.svg';
import quoctichIcon from '../../../assets/images/quoctich.svg';
import trangthaiIcon from '../../../assets/images/trangthai.svg';
import user1Icon from '../../../assets/images/user-1.svg';
import studentIcon from '../../../assets/images/user.svg';
import userIcon from '../../../assets/images/userCha.svg';

interface ChiTietProps {
	idHocSinh: string,
	nam_hoc: string
}

interface HocSinhChiTietResponse {
	id: number
	ho_ten: string
	ma_hs: string
	ma_dinh_danh: string
	ho_ten_giao_vien_phu_trach: string
	avatar: string
	ngay_sinh: string
	dm_quoctich_id: number
	dm_gioitinh_id: number
	dien_thoai_hocsinh: string
	email_hocsinh: string
	dia_chi_hientai: string
	dm_he_id: number
	dm_truong_id: number
	dm_khoi_id: number
	dm_lop_id: number
	dm_trangthaihocsinh_id: number,
	ten_khoi: string,
	ten_lop: string,
	ten_he: string,
	dm_trangthaihocsinh_name: string,
	dm_quoctich_name: string,
	dm_gioitinh_name: string,
	dia_chi_tt: string
}

interface HocSinhQuanHeResponse {
	id: number
	dm_quanhe_id: number
	ts_hocsinh_id: number
	ho_ten: number
	dien_thoai: number
	quan_he: number
}

const ChiTietThongTinHocSinhTab_Info = (props: ChiTietProps) => {
	const [dataReport, setDataReport] = useState<HocSinhChiTietResponse>();
	const [quanHes, setQuanHes] = useState<HocSinhQuanHeResponse[]>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const DataContext = createContext({ data: "", setData: (value: string) => {} });
	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.hoSo.SelectThongTinHocSinhAsync(props.idHocSinh, props.nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as HocSinhChiTietResponse;
			setDataReport(listCategory);
		} else {
			setDataReport(undefined);
		}

		const ress: BaseResponse = await rootApi.hoSo.SelectQuanHeHocSinhAsync(props.idHocSinh);
		if (ress.isSuccess && ress.result) {
			const listCategory = ress.result as HocSinhQuanHeResponse[];
			setQuanHes(listCategory);
		} else {
			setQuanHes(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [props.idHocSinh]);

	useEffect(() => {
		handleReloadData();
	}, [props.idHocSinh]);

	return (
		<>
			<div className="student-header">
				<img src={studentIcon} className="student-avatar" />
				<div className="student-info">
					<h2>{dataReport?.ho_ten}</h2>
					<span className="student-id">{dataReport?.ma_hs}</span>
					<p>GVCN: {dataReport?.ho_ten_giao_vien_phu_trach}</p>
				</div>
			</div>
			<div className="student-details">
				<ul>
					<li>
						<img src={madinhdanhIcon} />
						<span className="label">Mã định danh:</span>
						<span className="value">{dataReport?.ma_dinh_danh}</span>
					</li>
					<li>
						<img src={khoiIcon} />
						<span className="label">Khối:</span>
						<span className="value"> {dataReport?.ten_khoi}</span>
					</li>
					<li>
						<img src={lopIcon} />
						<span className="label">Lớp:</span>
						<span className="value"> {dataReport?.ten_lop}</span>
					</li>
					<li>
						<img src={heIcon} />
						<span className="label">Hệ:</span>
						<span className="value"> {dataReport?.ten_he}</span>
					</li>
					<li>
						<img src={trangthaiIcon} />
						<span className="label">Trạng thái:</span>
						<span className="value status-active"><b> {dataReport?.dm_trangthaihocsinh_name}</b></span>
					</li>
					<li>
						<img src={ngaysinhIcon} />
						<span className="label">Ngày sinh:</span>
						<span className="value">{moment(dataReport?.ngay_sinh).format('DD/MM/YYYY')}</span>
					</li>
					<li>
						<img src={quoctichIcon} />
						<span className="label">Quốc tịch:</span>
						<span className="value">{dataReport?.dm_quoctich_name}</span>
					</li>
					<li>
						<img src={gioitinhIcon} />
						<span className="label">Giới tính:</span>
						<span className="value">{dataReport?.dm_gioitinh_name}</span>
					</li>
					<li>
						<img src={dienthoaiIcon} />
						<span className="label">Điện thoại:</span>
						<span className="value">{dataReport?.dien_thoai_hocsinh}</span>
					</li>
					<li>
						<img src={emailIcon} />
						<span className="label">Email:</span>
						<span className="value" style={{width: '80%'}}>{dataReport?.email_hocsinh}</span>
					</li>
					<li>
						<img src={diachiIcon} />
						<span className="label">Địa chỉ:</span>
						<span className="value">{dataReport?.dia_chi_tt}</span>
					</li>
				</ul>
			</div>
			<div className="guardian-info">
				<h3>Thông tin phụ huynh, người giám hộ</h3>
				<ul>
					{quanHes?.map((item) =>
						<li>
							{item.dm_quanhe_id !== 2 && <img src={userIcon} />}
							{item.dm_quanhe_id === 2 && <img src={user1Icon} />}
							<span className="label" style={{minWidth: '50%', textAlign: 'left'}}> {item.ho_ten} ({item.quan_he})</span>
							<span className="value"> {item.dien_thoai}</span>
						</li>
					)}
				</ul>
			</div>
		</>
	);
};

export default ChiTietThongTinHocSinhTab_Info;

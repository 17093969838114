import { combineReducers } from 'redux';
import megaReducers from './mega/megaReducers';
import accountReducer from './my-system/auth/accountReducer';
import commonReducer from './my-system/common/commonReducer';

const rootReducers = combineReducers({
	common: commonReducer,
	account: accountReducer,
	mega: megaReducers
});

export type AppState = ReturnType<typeof rootReducers>;

export default rootReducers;

import React from 'react';

const formatNumber = new Intl.NumberFormat('en-US', {
	maximumFractionDigits: 0,
}).format;

export default function TooltipTemplate(info:any) {
	return (
		<div className="state-tooltip" style={{padding: 0, margin: 0}}>
			<h5 style={{backgroundColor:'#B6D1FF'}}>{info.point.data.thang_string}/{info.point.data.nam}</h5>
			<h6>Tổng số: {info.value}</h6>
			{/* <div className="capital">
				<span className="caption">Đang học</span>: {info.point.data.hocsinh_danghoc}
			</div> */}
			{/* <div className="population">
				<span className="caption">Chờ lớp</span>: {formatNumber(info.point.data.hocsinh_cholop)}
			</div>
			<div>
				<span className="caption">Bảo lưu</span>: <span>{formatNumber(info.point.data.hocsinh_baoluu)}</span>
			</div> */}
		</div>
	);
}

import { useMemo } from 'react';
import { DxChart, DxCommonSeriesSettings, DxLegend, DxSeries, DxTooltip } from '../../../components/common/DxCharts';

const customizeTooltip = (arg: any) => {
	return {
		text: `Số lượng ${arg.argumentText}: ${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
	};
};

interface BaoCaoTiLeChuyenDoiChartProps {
	data: any[];
}

const BaoCaoTiLeChuyenDoiChart = ({ data }: BaoCaoTiLeChuyenDoiChartProps) => {
	const listconversionRateDate = useMemo(() => {
		const _list = data.reduce((prev, curr) => {
			const data = curr.data;
			data.forEach((val: any) => {
				if (!prev[val.staff]) {
					prev[val.staff.toString()] = { [curr.name]: val.so_luong };
				} else {
					prev[val.staff.toString()] = {
						...prev[val.staff.toString()],
						[curr.name]: val.so_luong,
					};
				}
			});
			return prev;
		}, {});
		var rs = Object.keys(_list).map((val) => {
			return {
				staff: val.toString(),
				..._list[val],
			};
		});
		return rs;
	}, [data]);
	return (
		<DxChart id='chartTL' dataSource={listconversionRateDate} rotated>
			{/* <DxTitlePieChart text={'Tỉ lệ chuyển đổi'}>
				<DxFontPieChart size={12} weight={600} />
			</DxTitlePieChart> */}
			{/* <Size width={400} height={350} /> */}
			<DxCommonSeriesSettings argumentField='staff' type='bar' />
			{data.map((value) => (
				<DxSeries key={value.name} valueField={value.name} name={value.name} />
			))}

			<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='top' />
			<DxTooltip enabled={true} location='edge' customizeTooltip={customizeTooltip} />
		</DxChart>
	);
};

export default BaoCaoTiLeChuyenDoiChart;

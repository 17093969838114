import { baoCaoApi } from './mega/baoCaoApi';
import { categoryApi } from './mega/categoryApi';
import { hoSoApi } from './mega/hoSoApi';
import { AccountApi } from './my-system/accountApi';

export const rootApi = {
	account: AccountApi,
	category: categoryApi,
	baoCao: baoCaoApi,
	hoSo: hoSoApi
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './AppRoutes';
import { Loading } from './components/base';
import { eBaseActionStatus } from './context/base/eBaseActionStatus';
import { actions } from './context/stores/rootActions';
import { AppState } from './context/stores/rootReducers';
import { AdminLayout, PublicLayout } from './layouts';
import { NotFound } from './pages/my-system/error';
import { AuthProvider } from './utils/AuthProvider';

const App = () => {
	const dispatch = useDispatch();
	const { accountStatus, accountInfo } = useSelector((state: AppState) => state.account);

	useEffect(() => {
		if (accountStatus === eBaseActionStatus.idle) dispatch(actions.account.getAccountRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (accountStatus !== eBaseActionStatus.complete) return <Loading />;
	else {
		return (
			<>
				<AuthProvider account={accountInfo}>
					{!accountInfo ? (
						<>
							<Loading />
						</>
					) : (
						<>
							<Routes>
								<Route element={<AdminLayout />}>
									{AppRoutes &&
										AppRoutes.map(({ path, Component }, index) => {
											return <Route key={index} path={path} element={<Component />} />;
										})}
									<Route path='*' element={<NotFound />} />
								</Route>
								{/* <Route element={<PublicLayout />}>
							<Route path='/login' element={<Loading />} />
						</Route> */}
							</Routes>
						</>
					)}
				</AuthProvider>
				<ToastContainer
					position='bottom-center'
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover
				/>
			</>
		);
	}
};

export default App;

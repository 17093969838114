import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxGrid,
	DxLegend,
	DxMargin,
	DxSeries,
	DxTooltip,
} from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import TooltipTemplate from './TooltipTemplate';

const energySources = [
	{ value: 'hocsinh_total', name: 'Tổng số học sinh đang học' }
];

interface BaoCaoBienDongHocSinhChartResponse {
	ten_truong: string;
	ten_truong_en: string;
	thang: string;
	thang_string: string;
	nam: string;
	hocsinh_total: number;
	hocsinh_danghoc: number;
	hocsinh_cholop: number;
	hocsinh_baoluu: number;
}

const BaoCaoHocSinhTongQuanChart = () => {

	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoBienDongHocSinhChartResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectBienDongHocSinhThangAsync(dm_coso_id, dm_truong_id, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoBienDongHocSinhChartResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: BaoCaoBienDongHocSinhChartResponse[], source) => {
			const exited = khoiGroupData.find((x: BaoCaoBienDongHocSinhChartResponse) => x.thang === source.thang);
			if (!exited) {
				khoiGroupData.push(source);
			}
			return khoiGroupData;
		}, []);
		return huyenGrouped;
	}, [dataReport]);

	const khoiSoLieuGrouped = useMemo(() => {
		const khoiSoLieuGrouped = khoiGrouped.map((khoi) => {
			let result: any = { ...khoi, hocsinh_total: 0, hocsinh_danghoc: 0, hocsinh_cholop: 0, hocsinh_baoluu: 0 };
			const soLieuKhois = dataReport.filter((map_khoi) => map_khoi.thang === khoi.thang);
			if (soLieuKhois) {
				soLieuKhois.forEach((solieu) => {
					result = {
						...result,
						hocsinh_total: result.hocsinh_total + solieu.hocsinh_danghoc,
						// hocsinh_danghoc: result.hocsinh_danghoc + solieu.hocsinh_danghoc,
						// hocsinh_cholop: result.hocsinh_cholop + solieu.hocsinh_cholop,
						// hocsinh_baoluu: result.hocsinh_baoluu + solieu.hocsinh_baoluu
					};
				});
			}
			return result;
		});
		return khoiSoLieuGrouped;
	}, [khoiGrouped, dataReport]);

	return (
		<>
			<DxChart palette='Dark Violet' dataSource={khoiSoLieuGrouped} height={320}>
				<DxCommonSeriesSettings argumentField='thang' type={'line'}  color={'#EA7D0F'}/>
				{energySources.map((item) => (
					<DxSeries key={item.value} valueField={item.value} name={item.name}/>
				))}
				<DxMargin bottom={20} />
				<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
					<DxGrid visible={true} />
				</DxArgumentAxis>
				<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' />
				{/* <DxTitle text='Energy Consumption in 2004'></DxTitle> */}
				<DxTooltip
					enabled={true}
					contentRender={TooltipTemplate}
				/>
			</DxChart>
		</>
	);
};

export default BaoCaoHocSinhTongQuanChart;

import {
	DxConnectorPieChart,
	DxFontPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart,
	DxTitlePieChart,
} from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';
import { useMemo } from 'react';

interface dataReportType {
	id: number;
	ma_he: string;
	ten_he: string;
	ten_he_en: string;
	dm_truong_id: number;
	dm_coso_id: number;
	dm_he_id: number;
	dm_khoi_id: number;
	ma_khoi: string;
	ten_khoi: string;
	ten_khoi_en: string;
	hoc_sinh: number;
	ten_truong: string;
	ten_truong_en: string;
}

const dataSource = [
	{
		id: 1,
		title: 'Phẩm chất nhà giáo',
		tong: 0,
	},
	{
		id: 2,
		title: 'Phát triển chuyên môn, nghiệp vụ',
		tong: 0,
	},
	{
		id: 3,
		title: 'Xây dựng môi trường giáo dục',
		tong: 0,
	},
	{
		id: 4,
		title: 'Phát triển mối quan hệ giữa nhà trường, gia đình và xã hội',
		tong: 0,
	},
];

const customizeText = (arg: any) => {
	return `${arg.valueText} (${arg.percentText})`;
};
const GiaoVienTheoChuanNgheNghiep = () => {
	return (
		<>
			<div className='row g-gs justify-content-center'>
				{dataSource.map((khoi, idx) => {
					const soLieuTheoKhois = [
						{
							title: 'Tổng',
							total: 0,
						},
						{
							title: 'Mức đạt',
							total: 0,
						},
						{
							title: 'Mức khá',
							total: 0,
						},
						{
							title: 'Mức tốt',
							total: 0,
						},
					];
					return (
						<div className='col-md-6 col-xxl-6' key={idx}>
							<div className='card h-100'>
								<div className='card-inner'>
									<DxPieChart
										id={createUUID()}
										palette='Dark Violet'
										dataSource={soLieuTheoKhois}
										type='doughnut'
										// centerRender={(pieChart) => {
										// 	return (
										// 		<svg>
										// 			<circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
										// 			<text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
										// 				<tspan x='100'>{khoi.ten_khoi}</tspan>
										// 			</text>
										// 		</svg>
										// 	);
										// }}
										resolveLabelOverlapping={'shift'}
										className='w-100'
										height={220}
										// height={180}
									>
										<DxTitlePieChart text={khoi.title}>
											<DxFontPieChart size={12} weight={600} />
										</DxTitlePieChart>
										<DxLegendPieChart
											orientation='horizontal'
											itemTextPosition='right'
											horizontalAlignment='center'
											verticalAlignment='bottom'
											// columnCount={5}
										/>
										<DxSeriesPieChart argumentField='title' valueField='total'>
											<DxLabelPieChart visible={true} position='columns'>
												<DxConnectorPieChart visible={true} width={0.5} />
											</DxLabelPieChart>
										</DxSeriesPieChart>
									</DxPieChart>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default GiaoVienTheoChuanNgheNghiep;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootApi } from '../../../../context/api/rootApi';
import { BaseResponse } from '../../../../context/base/BaseResponse';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';

interface IChiTietProps{
    onValueChanged: (id: number) => void,
    value: number,
}

export const SelectBoxKhoi = (props : IChiTietProps) => {
	const dispatch = useDispatch();
	const { dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<any[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.hoSo.SelectDanhSachKhoiAsync(dm_truong_id);
		if (res.isSuccess && res.result) {
			const listCategory = res.result;
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [nam_hoc, dm_truong_id]);

	useEffect(() => {
		handleReloadData();
	}, [nam_hoc, dm_truong_id]);

	return (
		<DxSelectBox
			dataSource={dataReport}
			value={props.value}
            isClearable={true}
			onValueChanged={(value) => {
				props.onValueChanged(value || 0)
			}}
			valueExpr='id'
			displayExpr='ten_khoi'
			placeholder='Chọn khối'
			name={`namhoc_${createUUID()}`}
		/>
	);
};

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import {
	DxConnectorPieChart,
	DxLabelPieChart,
	DxLegendPieChart,
	DxPieChart,
	DxSeriesPieChart
} from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	dung_gio: number;
	den_muon: number;
	vang_co_phep: number;
	vang_khong_phep: number;
	tong_cong: number;
}

const customizeText = (arg: any) => {
	return `${arg.argumentText}: ${arg.percentText} <br/>${arg.valueText} học sinh`;
};
const ChartTinhTrangDiemDanh = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [dataChart, setDataChart] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isShowPopupDiemDanhNgay, setShowPopupDiemDanhNgay] = useState(false);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectChartTinhTrangDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	return (
		<>
			<div className="box box-1 no-margin">
				<div className="title">Tình trạng điểm danh</div>
				{(!dataReport || dataReport.length === 0) && <NoDataLoading />}
				{dataReport && dataReport.length > 0 &&
					<DxPieChart
						id={createUUID()}
						palette={['#1E8FF5', '#26CECB', '#8037ED', '#A6F6FF', '#001859']}
						dataSource={dataReport}
						resolveLabelOverlapping={'shift'}
						className='w-100'
						height={400}
					>
						<DxLegendPieChart
							orientation='horizontal'
							itemTextPosition='right'
							horizontalAlignment='center'
							verticalAlignment='bottom'
							columnCount={4}
						/>
						<DxSeriesPieChart argumentField='ten_he' valueField='tong_cong'>
							<DxLabelPieChart visible={true} position='columns' customizeText={customizeText}>
								<DxConnectorPieChart visible={true} width={0.5} />
							</DxLabelPieChart>
						</DxSeriesPieChart>
					</DxPieChart>
				}
			</div>
		</>
	);
};

export default ChartTinhTrangDiemDanh;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderSelectBoxCoSo, HeaderSelectBoxHocKy, HeaderSelectBoxNamHoc, HeaderSelectBoxTruong } from '../../components/shared/mega';
import { ChangePasswordRequest } from '../../context/models/my-system/accounts';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';

interface IHeaderProps {
	// showSideBar: () => void;
}
const Header = (props: IHeaderProps) => {

	const dispatch = useDispatch();
	// const { showSideBar } = props;
	const [isChangePassword, setChangePassword] = useState(false);
	const [userDropdown, setUserDropdown] = useState(false);
	const { accountStatus } = useSelector((state: AppState) => state.account);

	const changePassword = (data: ChangePasswordRequest) => {
		dispatch(actions.account.changePasswordRequest(data));
	};

	return (
		<>
			<div className="col-xs-12 form_inline form-dashboard-hocsinh">
				<div className="col-xs-3 form-group box-diemdanh-2">
					<label>Cơ sở</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxCoSo />
					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-3">
					<label>Cấp học</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxTruong />

					</div>
				</div>
				<div className="col-xs-3 form-group box-diemdanh-4">
					<label>Năm học</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxNamHoc />
					</div>
				</div>
				{/* <div className="col-xs-3 form-group box-diemdanh-4">
					<label>Học kỳ</label>
					<div className="custom-dropdown">
						<HeaderSelectBoxHocKy />
					</div>
				</div> */}
			</div>
		</>
	);
};

export default Header;

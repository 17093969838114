/* eslint-disable react-hooks/exhaustive-deps */

import ChiTietThongTinHocSinhTab_DiemDanh from './ChiTietThongTinHocSinhTab_DiemDanh';
import ChiTietThongTinHocSinhTab_Info from './ChiTietThongTinHocSinhTab_Info';
import ChiTietThongTinHocSinhTab_Tkb from './ChiTietThongTinHocSinhTab_Tkb';

interface ChiTietProps {
	idHocSinh: string,
	nam_hoc: string
}

const ChiTietThongTinHocSinhTab = (props: ChiTietProps) => {
	return (
		<>
			<div className="col-xs-12 info-student">
				<div className="col-xs-12 student-card">
					<ChiTietThongTinHocSinhTab_Info idHocSinh={props.idHocSinh} nam_hoc={props.nam_hoc} />
				</div>
				<div className="col-xs-12 detail-student">
					<div className="col-xs-12 box box-2 no-margin">
						<ChiTietThongTinHocSinhTab_DiemDanh idHocSinh={props.idHocSinh} nam_hoc={props.nam_hoc} />
					</div>
					<div className="col-xs-12">
						<ChiTietThongTinHocSinhTab_Tkb idHocSinh={props.idHocSinh} nam_hoc={props.nam_hoc} />
					</div>
				</div>
			</div>
		</>
	);
};

export default ChiTietThongTinHocSinhTab;

import React, { useState } from 'react';
import PopupHocSinhTheoHeBox from './PopupHocSinhTheoHeBox';

// Định nghĩa interface cho cấu trúc dữ liệu
interface DataItem {
	dm_khoi_id: number;
	ten_khoi: string;
	ten_khoi_en: string | null;
	rowspanKhoi: number;
	dm_he_id: number;
	ten_he: string;
	ten_he_en: string | null;
	rowspanHe: number;
	dm_lop_id: number;
	ten_lop: string;
	hocsinh_total: number;
}

// Định nghĩa props của component với kiểu dữ liệu
interface DataTableProps {
	data: DataItem[];
}

const DataTableTheoHe: React.FC<DataTableProps> = ({ data }) => {
	const grades = Array.from(new Set(data.map(item => item.dm_khoi_id)));
	const [expandedGrades, setExpandedGrades] = useState<{ [key: number]: boolean }>({});
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const toggleGrade = (grade: number) => {
		setExpandedGrades((prevState) => ({
			...prevState,
			[grade]: !prevState[grade],
		}));
	};

	const onOpenPopup = (idLopTmp: number) => {
		setShowPopup(true)
		// setTrangThai(trangThaiTmp)
		setIdLop(idLopTmp)
	}

	const onClose = () => {
		setShowPopup(false);
	}

	return (
		<>
			<table>
				<thead>
					<tr>
						<th>Khối</th>
						<th>Hệ đào tạo</th>
						<th>Lớp</th>
						<th>Số học sinh</th>
					</tr>
				</thead>
				<tbody>
					{grades.map(grade => {
						const gradeData = data.filter(item => item.dm_khoi_id === grade);
						const isExpanded = expandedGrades[grade];

						return (
							<React.Fragment key={grade}>
								<tr>
									<td colSpan={3} style={{ cursor: 'pointer' }} onClick={() => toggleGrade(grade)}>
										<a href='#' className='toggle-grade'>{isExpanded && gradeData.length > 0 ? gradeData[0].ten_khoi : gradeData[0].ten_khoi}</a>
									</td>
									<td style={{ textAlign: 'center', fontWeight: '500' }}>
										{gradeData.reduce((sum, item) => sum + item.hocsinh_total, 0)}
									</td>
								</tr>
								{isExpanded && gradeData.map((item, index) => (
									<tr key={item.dm_lop_id}>
										{index === 0 && item.ten_khoi && (
											<td rowSpan={item.rowspanKhoi}>{item.ten_khoi}</td>
										)}
										{item.ten_he && (
											<td rowSpan={item.rowspanHe}>{item.ten_he}</td>
										)}
										<td style={{ textAlign: 'center' }}>{item.ten_lop}</td>
										<td style={{ textAlign: 'center' }}><a href='#' onClick={() => onOpenPopup(item.dm_lop_id)}>{item.hocsinh_total}</a></td>
									</tr>
								))}
							</React.Fragment>
						);
					})}
					<tr className="expandable-row" style={{ backgroundColor: '#f5f5f5' }}>
						<td colSpan={3} style={{ fontWeight: 'bold' }}>Tổng cộng</td>
						<td style={{ textAlign: 'center', fontWeight: 'bold' }}>{data.reduce((sum, item) => sum + item.hocsinh_total, 0)}</td>

					</tr>
				</tbody>
			</table>
			{isShowPopup && <PopupHocSinhTheoHeBox dm_lop_id={idLop} onClose={onClose} />}
		</>
	);

};

export default DataTableTheoHe;
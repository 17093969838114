import { HeaderSelectBoxHocKy } from "../../components/shared/mega";
import Header from "../../layouts/admin/Header";
import BaoCaoHocSinhTheoHeBox from "./hoc-sinh-theo-he/BaoCaoHocSinhTheoHeBox";
import BaoCaoHocSinhTheoTrangThaiBox from "./hoc-sinh-theo-trang-thai/BaoCaoHocSinhTheoTrangThaiBox";
import BaoCaoHocSinhKetQuaHocTapBox from "./ket-qua-hoc-tap/BaoCaoHocSinhKetQuaHocTapBox";
import BaoCaoTongQuanBox from "./tong-quan/BaoCaoTongQuanBox";

const DashboardHocSinhPage = () => {
	return (
		<>
			<div className="col-xs-12 header-container">
				<div className="col-xs-12 frame-box">
					<Header />
				</div>
				{/* <!-- danh muc dung chung --> */}
				<div className="col-xs-12 bg-frame">
					<BaoCaoTongQuanBox />
				</div>
				{/* <!-- end --> */}
				<div className="col-xs-12 bg-frame">
					<div className="col-xs-12 title-detail">
						<span>Chỉ số học sinh theo hệ đào tạo</span>
					</div>
					<div className="col-xs-12 box-content">
						<BaoCaoHocSinhTheoHeBox />
					</div>
				</div>
				<div className="col-xs-12 bg-frame">
					<div className="col-xs-12 title-detail">
						<span>Chỉ số học sinh nhập học, nghỉ học, bảo lưu</span>
					</div>
					<div className="col-xs-12 box-content">
						<BaoCaoHocSinhTheoTrangThaiBox />
					</div>
				</div>
				<div className="col-xs-12 bg-frame">
					<div className="col-xs-12 title-detail box-content">
						<div className="col-xs-11 form-group">
							<span>Kết quả học tập</span>
						</div>
						<div className="col-xs-1 form-group">
							<div className="custom-dropdown">
								<HeaderSelectBoxHocKy />
							</div>
						</div>
					</div>
					<div className="col-xs-12 box-content">
						<BaoCaoHocSinhKetQuaHocTapBox />
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardHocSinhPage;

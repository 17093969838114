import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DxChart, DxCommonSeriesSettings, DxFormat, DxLabel, DxLegend, DxSeries } from '../../../components/common/DxCharts';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';

interface BaoCaoNhanSuResponse {
	dm_truong_id: number;
	ten_truong: string;
	ten_truong_en: string;
	giaovien_total: number;
	nhanvien_total: number;
	quanly_total: number;
}

const BaoCaoNhanSuChart = () => {
	const [dataReport, setDataReport] = useState<BaoCaoNhanSuResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoNhanSuAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoNhanSuResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);


	const truongTotals = useMemo(() => {
		return dataReport?.map(x => ({...x, nhanvien_total: 0 }));
	}, [dataReport]);
	
	return (
		<>
			<DxChart id={createUUID()} dataSource={truongTotals} className='w-100' palette={'Carmine'} height={220}>
				{/* <DxTitle text={`Thống kê nhân sự các trường`}>
					<DxFont color='black' size={18} weight={600} />
				</DxTitle> */}
				<DxCommonSeriesSettings argumentField='ten_truong' type='bar' hoverMode='allArgumentPoints' selectionMode='allArgumentPoints'>
					<DxLabel visible={true}>
						<DxFormat type='fixedPoint' precision={0} />
					</DxLabel>
				</DxCommonSeriesSettings>
				<DxSeries valueField='giaovien_total' argumentField='ten_truong' name='Giáo viên' type='bar'></DxSeries>
				<DxSeries valueField='nhanvien_total' argumentField='ten_truong' name='Nhân viên' type='bar'></DxSeries>
				<DxSeries valueField='quanly_total' argumentField='ten_truong' name='Quản lý' type='bar'></DxSeries>
				<DxLegend orientation='horizontal' verticalAlignment='bottom' horizontalAlignment='center' />
			</DxChart>
		</>
	);
};

export default BaoCaoNhanSuChart;

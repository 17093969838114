import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { useCallback, useEffect, useState } from 'react';
import { rootApi } from '../../../../context/api/rootApi';
import { BaseResponse } from '../../../../context/base/BaseResponse';
interface IChiTietProps {
	onValueChanged: (id: number) => void,
	value: number
}
export const SelectBoxTrangThai = (props: IChiTietProps) => {

	const dispatch = useDispatch();
	const {dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<any[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.hoSo.SelectDanhSachTrangThaiAsync(dm_coso_id, dm_truong_id);
		if (res.isSuccess && res.result) {
			const listCategory = res.result;
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, dm_truong_id]);

	return (
		<DxSelectBox
			dataSource={dataReport}
			isClearable={true}
			value={props.value}
			// width={150}
			onValueChanged={(value) => {
				props.onValueChanged(value || 0)
			}}
			valueExpr='id'
			displayExpr='text'
			placeholder='Chọn trạng thái'
			name={`trangthai_${createUUID()}`}
		// label='Năm làm việc'
		// labelSmall={true}
		/>
	);
};

import { useMemo, useState, useCallback, useEffect } from 'react';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxGrid,
	DxLegend,
	DxMargin,
	DxSeries,
	DxTooltip,
} from '../../../components/common/DxCharts';
import { useLookupKhoi } from '../../../components/shared/mega';
import { useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { DiemDanhResponse } from '../../../context/models/mega/DiemDanhResponse';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';

const energySources = [
	{ value: 'chua_diem_danh', name: 'Chưa điểm danh' },
	{ value: 'dung_gio', name: 'Đúng giờ' },
	{ value: 'den_muon', name: 'Đến muộn' },
	{ value: 'vang_co_phep', name: 'Vắng có phép' },
	{ value: 'vang_khong_phep', name: 'Vắng không phép' },
];

interface ThongKeDiemDanhNgayLineProps {
	ngay_diem_danh: string;
}

const ThongKeDiemDanhNgayLine = (props: ThongKeDiemDanhNgayLineProps) => {
	const { ngay_diem_danh } = props;
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<DiemDanhResponse[]>([]);

	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectDiemDanhAsync(dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as DiemDanhResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc, ngay_diem_danh]);

	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: DiemDanhResponse[], source) => {
			const exited = khoiGroupData.find((x: DiemDanhResponse) => x.dm_khoi_id === source.dm_khoi_id);
			if (!exited) {
				khoiGroupData.push(source);
			}
			return khoiGroupData;
		}, []);
		return huyenGrouped;
	}, [dataReport]);

	const khoiSoLieuGrouped = useMemo(() => {
		const khoiSoLieuGrouped = khoiGrouped.map((khoi) => {
			let result: any = { ...khoi, chua_diem_danh: 0, dung_gio: 0, den_muon: 0, vang_co_phep: 0, vang_khong_phep: 0 };
			const soLieuKhois = dataReport.filter((map_khoi) => map_khoi.dm_khoi_id === khoi.dm_khoi_id);
			if (soLieuKhois) {
				soLieuKhois.forEach((solieu) => {
					result = {
						...result,
						chua_diem_danh: result.chua_diem_danh + solieu.chua_diem_danh,
						dung_gio: result.dung_gio + solieu.dung_gio,
						den_muon: result.den_muon + solieu.den_muon,
						vang_co_phep: result.vang_co_phep + solieu.vang_co_phep,
						vang_khong_phep: result.vang_khong_phep + solieu.vang_khong_phep,
					};
				});
			}
			return result;
		});
		return khoiSoLieuGrouped.sort((a, b) => (a.ten_khoi > b.ten_khoi ? 1 : -1));
	}, [khoiGrouped, dataReport]);

	return (
		<>
			<DxChart palette='Dark Violet' dataSource={khoiSoLieuGrouped} height={320}>
				<DxCommonSeriesSettings argumentField='ten_khoi' type={'line'} />
				{energySources.map((item) => (
					<DxSeries key={item.value} valueField={item.value} name={item.name} />
				))}
				<DxMargin bottom={20} />
				<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
					<DxGrid visible={true} />
				</DxArgumentAxis>
				<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' />
				{/* <DxTitle text='Energy Consumption in 2004'></DxTitle> */}
				<DxTooltip enabled={true} />
			</DxChart>
		</>
	);
};

export default ThongKeDiemDanhNgayLine;

import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';

export const HeaderSelectBoxHocKy = () => {
	const dispatch = useDispatch();
	const { hoc_ky } = useSelector((state: AppState) => state.common);
	return (
		<DxSelectBox
			dataSource={[
				{ id: 1, text: 'Học kỳ 1' },
				{ id: 2, text: 'Học kỳ 2' },
				{ id: 0, text: 'Cả năm' },
			]}
			// isClearable={true}
			value={hoc_ky}
			// width={150}
			onValueChanged={(value) => {
				dispatch(actions.common.changeHOCKY(value || 0));
			}}
			valueExpr='id'
			displayExpr='text'
			placeholder='Chọn Học kỳ'
			name={`hocky_${createUUID()}`}
			// label='Năm làm việc'
			// labelSmall={true}
		/>
	);
};

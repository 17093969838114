import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { useLookupTruong } from './useLookupTruong';
import { createUUID } from '../../../../utils/createUUID';
import { useAuth } from '../../../../utils/AuthProvider';
import { DxTagBox } from '../../../base/DxTagBox';


export const HeaderSelectBoxTruong = () => {
	const dispatch = useDispatch();

	const { account } = useAuth();

	const { dm_truong_id, dm_coso_id } = useSelector((state: AppState) => state.common);

	const dm_truongs = useMemo(() => account?.dm_truongs || [], [account]);

	const dataSource = useMemo(() => {
		return dm_truongs.filter(x => x.dm_coso_id === dm_coso_id);
	}, [dm_coso_id, dm_truongs]);

	const [valuess, setvaluess] = useState<number[]>();

	return (
		// <DxTagBox
		// dataSource={dataSource}
		// 	value={valuess}
        //     isClearable={true}
		// 	onValueChanged={(value) => {
		// 		//dispatch(actions.common.changeTRUONG(value || 0));
		// 	}}
		// 	valueExpr='id'
		// 	displayExpr='ten_truong'
		// 	placeholder='Chọn Trường'
		// 	name={`namhoc_${createUUID()}`}
		// />
		<DxSelectBox
			dataSource={dataSource}
			value={dm_truong_id}
            isClearable={true}
			onValueChanged={(value) => {
				dispatch(actions.common.changeTRUONG(value || 0));
			}}
			valueExpr='id'
			displayExpr='ten_truong'
			placeholder='Chọn Trường'
			name={`namhoc_${createUUID()}`}
			// label='Năm làm việc'
			// labelSmall={true}
		/>
	);
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DxChart,
	DxCommonSeriesSettings,
	DxFormat,
	DxLabel,
	DxLegend,
	DxSeries,
	DxTooltip
} from '../../../components/common/DxCharts';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import { Tooltip } from 'devextreme-react';

interface BaoCaoHocSinhTheoTrangThaiResponse {
	thang: number;
	thang_string: string;
	nhap_hoc: number;
	nghi_hoc: number;
	bao_luu: number;
}

const customizeText = (arg: any) => {
	return `${arg.valueText} (${arg.percentText})`;
};
const BaoCaoHocSinhTheoTrangThaiPieChart = () => {
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoTrangThaiResponse[]>([]);
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.SelectChartNghiHocBaoLuuAsync(dm_coso_id, dm_truong_id, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoTrangThaiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_truong_id, nam_hoc, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, dm_truong_id, nam_hoc]);

	function customizeTooltip(arg: { seriesName: string; valueText: string; }) {
		return {
		  text: `${arg.seriesName}: ${arg.valueText}`,
		};
	  }
	return (
		<>
			{(!dataReport || dataReport.length === 0) && <NoDataLoading />}
			{dataReport && dataReport.length > 0 &&
				<DxChart id={createUUID()} dataSource={dataReport} className='w-100' palette={['#EA7D0F', '#26CECB', '#BBDFFF']} height={400}>
					<DxCommonSeriesSettings argumentField='thang' type='bar' hoverMode='allArgumentPoints' selectionMode='allArgumentPoints'>
						<DxLabel visible={false}>
							{/* <DxFormat type='fixedPoint' /> */}
						</DxLabel>
					</DxCommonSeriesSettings>
					<DxSeries valueField='nhap_hoc' argumentField='thang_string' name='Nhập học' type='bar'></DxSeries>
					<DxSeries valueField='bao_luu' argumentField='thang_string' name='Bảo lưu' type='bar'></DxSeries>
					<DxSeries valueField='nghi_hoc' argumentField='thang_string' name='Thôi học' type='bar'></DxSeries>
					<DxLegend orientation='horizontal' verticalAlignment='bottom' horizontalAlignment='center' />
					<DxTooltip
						enabled={true}
						location="edge"
						customizeTooltip={customizeTooltip}
					/>
				</DxChart>
			}
		</>
	);
};

export default BaoCaoHocSinhTheoTrangThaiPieChart;

import { CommonActionTypes, eCommonActionTypeIds } from './ICommonActionTypes';
import { ICommonState } from './ICommonState';

const initialState: ICommonState = {
	menu_mode: localStorage.menu_mode ?? 'show',
	dm_coso_id: localStorage.dm_coso_id ? parseInt(localStorage.dm_coso_id) : 0,
	dm_truong_id: localStorage.dm_truong_id ? parseInt(localStorage.dm_truong_id) : 0,
	nam_hoc: localStorage.nam_hoc ? localStorage.nam_hoc : '',
	hoc_ky: localStorage.hoc_ky ? parseInt(localStorage.hoc_ky) : 0,
	dm_tu_van_vien_id: 0,
	tu_ngay: localStorage.tu_ngay ? localStorage.tu_ngay : '',
	den_ngay: localStorage.den_ngay ? localStorage.den_ngay : '',
	tuan: localStorage.tuan ? parseInt(localStorage.tuan) : 0,
};

const commonReducer = (state: ICommonState = initialState, action: CommonActionTypes): ICommonState => {
	switch (action.type) {
		case eCommonActionTypeIds.CHANGE_MENU_MODE:
			localStorage.menu_mode = action.payload;
			return {
				...state,
				menu_mode: action.payload,
			};
		case eCommonActionTypeIds.CHANGE_COSO_MODE:
			localStorage.dm_coso_id = action.payload;
			localStorage.dm_truong_id = 0;
			return {
				...state,
				dm_coso_id: action.payload,
				dm_truong_id: 0,
			};
		case eCommonActionTypeIds.CHANGE_TRUONG_MODE:
			localStorage.dm_truong_id = action.payload;
			return {
				...state,
				dm_truong_id: action.payload,
			};
		case eCommonActionTypeIds.CHANGE_HOCKY_MODE:
			localStorage.hoc_ky = action.payload;
			return {
				...state,
				hoc_ky: action.payload,
			};
		case eCommonActionTypeIds.CHANGE_NAMHOC_MODE:
			localStorage.nam_hoc = action.payload;
			return {
				...state,
				nam_hoc: action.payload,
			};

		case eCommonActionTypeIds.HEADER_RESET_SELECTED:
			localStorage.menu_mode = 'show';
			return {
				...state,
				menu_mode: 'show',
			};
		case eCommonActionTypeIds.CHANGE_TUVANVIEN_MODE:
			return {
				...state,
				dm_tu_van_vien_id: action.payload,
			};
		case eCommonActionTypeIds.CHANGE_TUNGAY_MODE:
			localStorage.tu_ngay = action.payload;
			return {
				...state,
				tu_ngay: action.payload
			};
		case eCommonActionTypeIds.CHANGE_DENNGAY_MODE:
			localStorage.den_ngay = action.payload;
			return {
				...state,
				den_ngay: action.payload
			};
		case eCommonActionTypeIds.CHANGE_TUAN_MODE:
			localStorage.tuan = action.payload;
			return {
				...state,
				tuan: action.payload,
			};
		default:
			return state;
	}
};
export default commonReducer;

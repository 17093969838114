import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootApi } from '../../../../context/api/rootApi';
import { BaseResponse } from '../../../../context/base/BaseResponse';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';
import { createUUID } from '../../../../utils/createUUID';
import { DxSelectBox } from '../../../base/DxSelectBox';

interface IChiTietProps{
    onValueChanged: (id: number) => void,
    value: number,
	idKhoi: number
}

export const SelectBoxHe = (props : IChiTietProps) => {
	const { dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<any[]>([]);
	const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.hoSo.SelectDanhSachHeAsync(dm_truong_id, props.idKhoi, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result;
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_truong_id, props.idKhoi, nam_hoc]);

	useEffect(() => {
		handleReloadData();
	}, [dm_truong_id, props.idKhoi, nam_hoc]);

	return (
		<DxSelectBox
			dataSource={dataReport}
			value={props.value}
            isClearable={true}
			onValueChanged={(value) => {
				props.onValueChanged(value || 0)
			}}
			valueExpr='id'
			displayExpr='ten_he'
			placeholder='Chọn hệ'
			name={`namhoc_${createUUID()}`}
		/>
	);
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';

export function useLookupTruong() {
	const dispatch = useDispatch();
	const { dm_truong_all } = useSelector((state: AppState) => state.mega.category);
	useEffect(() => {
		if (dm_truong_all.length === 0) dispatch(actions.mega.category.getTRUONGRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { dm_truong_all };
}

import moment from 'moment';
import { DxDateBox } from '../../components/base';

import GiaoVienTheoKhoi from './bao_cao_chart/GiaoVienTheoKhoi';
import GiaoVienTheoTrinhDo from './bao_cao_chart/GiaoVienTheoTrinhDo';
import GiaoVienTheoChuanNgheNghiep from './bao_cao_chart/GiaoVienTheoChuanNgheNghiep';
import GiaoVienTheoChucDanh from './bao_cao_chart/GiaoVienTheoChucDanh';
import BaoCaoNhanSuChart from './bao_cao_chart/BaoCaoNhanSuChart';
import BaoCaoNhanSuPieChart from './bao_cao_chart/BaoCaoNhanSuPieChart';
import BaoCaoTongBox from './bao_cao_chart/BaoCaoTongBox';

const BaoCaoGiaoVienPage = () => {
	const khois = [
		{
			id: 1,
			name: 'Khối 1',
		},
		{
			id: 1,
			name: 'Khối 2',
		},
		{
			id: 1,
			name: 'Khối 3',
		},
		{
			id: 1,
			name: 'Khối 4',
		},
		{
			id: 1,
			name: 'Khối 5',
		},
	];
	return (
		<>
			<BaoCaoTongBox />
			<div className='nk-block'>
				<div className='row g-gs'>
					<div className='col-md-4 col-xxl-4'>
						<div className='card'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Số lượng nhân sự</h6>
								</div>
								<BaoCaoNhanSuPieChart />
							</div>
						</div>
					</div>
					<div className='col-md-8 col-xxl-8'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Giáo viên - Quản lý - Nhân viên</h6>
								</div>
								<BaoCaoNhanSuChart />
							</div>
						</div>
					</div>
					{/* <div className='col-md-12 col-xxl-12'>
						<div className='card h-100'>
							<div className='card-inner'>
								<div className='card-title card-title-sm'>
									<h6 className='title'>Theo khối</h6>
								</div>
								<GiaoVienTheoKhoi />
							</div>
						</div>
					</div> */}
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Chất lượng giáo viên</h6>
									</div>
								</div>
								<GiaoVienTheoTrinhDo />
							</div>
						</div>
					</div>
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Chuẩn nghề nghiệp giáo viên</h6>
									</div>
								</div>
								<GiaoVienTheoChuanNgheNghiep />
							</div>
						</div>
					</div>
					<div className='col-md-12 col-xxl-12'>
						<div className='card card-full h-100'>
							<div className='card-inner'>
								<div className='card-title-group pb-3'>
									<div className='card-title card-title-sm'>
										<h6 className='title'>Chức danh nghề nghiệp giáo viên</h6>
									</div>
								</div>
								<GiaoVienTheoChucDanh />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaoCaoGiaoVienPage;

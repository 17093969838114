import React, { useState } from 'react';
import PopupKetQuaHocTapBox from './PopupKetQuaHocTapBox';

interface BaoCaoHocSinhKqhtTableResponse {
	dm_xep_hangs: string[],
	dm_hoc_sinh_xep_hangs: HocSinhKqhtChiTietResponse[]
}

interface HocSinhKqhtChiTietResponse {
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	ten_lop: string;
	ten_lop_en: string;
	rowspanKhoi: number;
	rowspanHe: number;
	so_luongs: number[];
	dm_lop_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
}

// Định nghĩa props
interface DataTableProps {
	dataReport: {
		dm_xep_hangs: string[];
		dm_hoc_sinh_xep_hangs: HocSinhKqhtChiTietResponse[];
	};
}

const DataTableKetQuaHocTap: React.FC<DataTableProps> = ({ dataReport }) => {
	const [expandedGrades, setExpandedGrades] = useState<{ [key: number]: boolean }>({});
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const [xepHang, setXepHang] = useState('');

	const toggleGrade = (gradeId: number) => {
		setExpandedGrades((prevState) => ({
			...prevState,
			[gradeId]: !prevState[gradeId],
		}));
	};

	const onClose = () => {
		setShowPopup(false);
	}

	const onOpenPopup = (idLopTmp: number, ten_xep_hang: string) => {
		setIdLop(idLopTmp)
		setXepHang(ten_xep_hang)
		setShowPopup(true)
	}

	function calculateTotal(data: any[], dmKhoiId: number, thuTu: number): number {
		return data
			.filter(item => item.dm_khoi_id === dmKhoiId) // Lọc theo điều kiện dm_khoi_id = 6
			.reduce((total, item) => total + (item.so_luongs[thuTu] || 0), 0); // Tính tổng phần tử đầu tiên trong mảng so_luongs
	}

	function calculateTotalAll(data: any[], thuTu: number): number {
		// Tính tổng phần tử đầu tiên trong mảng so_luongs
		return data.reduce((total, item) => total + (item.so_luongs[thuTu] || 0), 0);
	}

	return (
		<>
			<table>
				<thead>
					<tr>
						<th>Khối</th>
						<th>Hệ</th>
						<th>Lớp</th>
						{dataReport?.dm_xep_hangs &&
							dataReport.dm_xep_hangs.map((item, index) => (
								<th key={index} className="text-center">{item}</th>
							))}
					</tr>
				</thead>
				<tbody>
					{dataReport?.dm_hoc_sinh_xep_hangs &&
						dataReport.dm_hoc_sinh_xep_hangs.reduce<JSX.Element[]>((acc, item, index) => {
							const isExpanded = expandedGrades[item.dm_khoi_id];
							const isTotal = item.ten_khoi === 'Tổng cộng';

							// Nếu là hàng đầu tiên hoặc "Tổng cộng", thêm hàng chính
							if (
								index === 0 ||
								dataReport.dm_hoc_sinh_xep_hangs[index - 1].dm_khoi_id !== item.dm_khoi_id ||
								isTotal
							) {
								acc.push(
									<tr
										key={`header-${item.dm_khoi_id}`}
										className="expandable-row"
										style={{ backgroundColor: isTotal ? '#f5f5f5' : '' }}
									>
										<td
											colSpan={3}
											style={{ cursor: isTotal ? 'default' : 'pointer', fontWeight: isTotal ? 'bold' : 'normal' }}
											onClick={!isTotal ? () => toggleGrade(item.dm_khoi_id) : undefined}
										>
											{!isTotal && <a href='#' className={!isTotal ? 'toggle-grade' : ''}>{item.ten_khoi}</a>}
											{isTotal && item.ten_khoi}
										</td>
										{!isTotal &&
											item.so_luongs.map((itemXh, i) => (
												<td
													key={`total-${i}`}
													className="text-center"
													style={{ fontWeight: '500' }}
												>
													{calculateTotal(dataReport.dm_hoc_sinh_xep_hangs, item.dm_khoi_id, i)}
												</td>
											))}
										{isTotal &&
											item.so_luongs.map((itemXh, i) => (
												<td
													key={`summary-${i}`}
													className="text-center"
													style={{ fontWeight: 'bold' }}
												>
													{itemXh > 0 ? itemXh : '-'}
												</td>
											))}
									</tr>
								);
							}

							// Nếu đang mở rộng, thêm hàng chi tiết
							if (isExpanded && !isTotal) {
								acc.push(
									<tr key={`details-${index}`}>
										<td hidden={item.rowspanKhoi === 0} rowSpan={item.rowspanKhoi}>{item.ten_khoi}</td>
										<td hidden={item.rowspanHe === 0} rowSpan={item.rowspanHe}>{item.ten_he}</td>
										<td>{item.ten_lop}</td>
										{item.so_luongs.map((itemXh, i) => (
											<td
												key={`detail-${i}`}
												className="text-center"
											>
												{itemXh > 0 ? (
													<a href="#" onClick={() => onOpenPopup(item.dm_lop_id, dataReport?.dm_xep_hangs[i])}>
														{itemXh}
													</a>
												) : (
													'-'
												)}
											</td>
										))}
									</tr>
								);
							}
							return acc;
						}, [])}
					{dataReport.dm_hoc_sinh_xep_hangs.length > 0 &&
						<tr className="expandable-row" style={{ backgroundColor: '#f5f5f5' }}>
							<td colSpan={3} style={{ fontWeight: 'bold' }}>Tổng cộng</td>
							{dataReport.dm_hoc_sinh_xep_hangs[0].so_luongs.map((itemXh, i) => (
								<td style={{ textAlign: 'center', fontWeight: 'bold' }}>{calculateTotalAll(dataReport.dm_hoc_sinh_xep_hangs, i)}</td>
							))}
						</tr>
					}
				</tbody>
			</table>
			{isShowPopup && <PopupKetQuaHocTapBox dm_lop_id={idLop} xep_hang={xepHang} onClose={onClose} />}
		</>
	);
};

export default DataTableKetQuaHocTap;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../context/stores/rootActions';
import { AppState } from '../../../../context/stores/rootReducers';

export function useLookupNamHoc() {
	const dispatch = useDispatch();
	const { dm_namhoc_all } = useSelector((state: AppState) => state.mega.category);
	useEffect(() => {
		if (dm_namhoc_all.length === 0) dispatch(actions.mega.category.getNAMHOCRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { dm_namhoc_all };
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import BaoCaoHocSinhTheoHePieChart from './BaoCaoHocSinhTheoHePieChart';
import DataTableTheoHe from './DataTableTheoHe';

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	hocsinh_total: number;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string
}

const BaoCaoHocSinhTheoHeBox = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc, hoc_ky } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	// const [trangThai, setTrangThai] = useState(-1);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectTableHocSinhTheoHeAsync(dm_coso_id, dm_truong_id, nam_hoc, hoc_ky);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, hoc_ky]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id]);



	return (
		<>
			{/* <!-- Tổng số học sinh box (tỉ lệ 1) --> */}
			<div className="box box-1">
				{!isLoadingTableTheoHe && (!dataReport || dataReport.length === 0) && <NoDataLoading />}
				{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
					<BaoCaoHocSinhTheoHePieChart />
				}
			</div>
			{/* <!-- Biểu đồ box (tỉ lệ 2) --> */}
			<div className="box box-2 no-margin">
				{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
					<>
						<div className="title">Chi tiết chỉ số học sinh theo hệ đào tạo</div>
					</>
				}
				{!isLoadingTableTheoHe && (!dataReport || dataReport.length === 0) && <NoDataLoading />}
				<div className="table-container">
					{isLoadingTableTheoHe &&
						<Loadingv2 />
					}
					{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
						<>
							<DataTableTheoHe data={dataReport} />
						</>
					}
				</div>
			</div>
		</>
	);
};

export default BaoCaoHocSinhTheoHeBox;

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import imgButton from '../../../assets/images/button.svg';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { Loadingv2 } from '../../../components/base/Loadingv2';
import * as XLSX from 'xlsx'; // Đổi cách import
import { saveAs } from 'file-saver'; // Không thay đổi

interface BaoCaoHocSinhTheoKhoiResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	dm_he_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	ten_he: string;
	ten_he_en: string;
	rowspanHe: number,
	rowspanKhoi: number,
	dm_lop_id: number,
	ten_lop: string;
	chua_diem_danh: number;
	trang_thai: number;
	giao_vien_phu_trach: string;
	gio_diem_danh: string;
	ten_hoc_sinh: string;
	ghi_chu: string;
	ngay_diem_danh: string;
	ma_hoc_sinh: string;
	ten_trang_thai: string;
}

interface PopUpDiemDanhNgayProps {
	onClose: () => void;
	trang_thai_diem_danh: number;
	dm_lop_id: number;
}

const PopupTinhTrangDiemDanhBox = (props: PopUpDiemDanhNgayProps) => {
	const { dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhTheoKhoiResponse[]>([]);
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.baoCao.SelectDanhSachHocSinhDiemDanhPopupAsync(dm_coso_id, dm_truong_id, nam_hoc, tu_ngay, den_ngay, props.trang_thai_diem_danh, props.dm_lop_id);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhTheoKhoiResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
		SetIsLoadingTableTheoHe(false);
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	useEffect(() => {
		handleReloadData();
	}, [dm_coso_id, nam_hoc, dm_truong_id, tu_ngay, den_ngay]);

	const handleExportExcel = () => {
		if (dataReport.length === 0) {
			alert('Không có dữ liệu để xuất.');
			return;
		}

		// Lấy thời gian hiện tại để đặt vào tên file
		const now = moment().format('YYYY-MM-DD_HH-mm-ss'); // Định dạng: Năm-Tháng-Ngày_Giờ-Phút-Giây
		const fileName = `DanhSachTinhTrangDiemDanh_${now}.xlsx`;

		// Chuẩn bị dữ liệu để xuất ra, bao gồm cột Số thứ tự
		const dataToExport = dataReport.map((item, index) => ({
			'STT': index + 1, // Số thứ tự
			'Ngày điểm danh': moment(item.ngay_diem_danh).format('DD/MM/yyyy'),
			'Mã học sinh': item.ma_hoc_sinh,
			'Họ tên': item.ten_hoc_sinh,
			'Hệ': item.ten_he,
			'Lớp': item.ten_lop,
			'Trạng thái': item.ten_trang_thai,
			'GV phụ trách': item.giao_vien_phu_trach,
			'Giờ điểm danh': item.gio_diem_danh,
		}));

		// Tạo worksheet từ dữ liệu
		const worksheet = XLSX.utils.json_to_sheet(dataToExport);

		// Đặt kích thước cột (bao gồm cột STT)
		worksheet['!cols'] = [
			{ wpx: 50 },  // STT
			{ wpx: 120 }, // Ngày điểm danh
			{ wpx: 120 }, // Mã học sinh
			{ wpx: 200 }, // Họ tên
			{ wpx: 150 }, // Hệ
			{ wpx: 100 }, // Lớp
			{ wpx: 100 }, // Trạng thái
			{ wpx: 150 }, // GV phụ trách
			{ wpx: 120 }, // Giờ điểm danh
		];

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách điểm danh');

		// Xuất file Excel
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
		saveAs(file, fileName); // Lưu file với tên chứa ngày giờ
	};

	return (
		<>
			{/* <!-- chi tiet diem danh --> */}
			< div className="modal diemdanh-detail show" id="myModal" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="col-xs-12 modal-content">
						<div className="col-xs-12 modal-header">
							<h4 className="modal-title">Danh sách học sinh điểm danh</h4>
							<button type="button" className="close" onClick={props.onClose}><img src={imgButton} /></button>
						</div>
						<div className="col-xs-12 modal-body">
							<button className="btn btn-primary" onClick={handleExportExcel} style={{ float: 'right' }}>
								Export Excel
							</button>
							<div className="col-xs-12 table-container">
								{isLoadingTableTheoHe &&
									<Loadingv2 />
								}
								{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
									<table id="attendanceTable">
										<thead>
											<tr>
												<th style={{ width: '20px' }}>STT</th>
												<th style={{ width: '50px' }}>Ngày điểm danh</th>
												<th style={{ width: '50px' }}>Mã học sinh</th>
												<th>Họ tên</th>
												<th>Hệ</th>
												<th className="text-center" style={{ width: '50px' }}>Lớp</th>
												<th style={{ width: '50px' }}>Trạng thái</th>
												<th style={{ width: '50px' }}>GV phụ trách</th>
												<th style={{ width: '50px' }}>Giờ điểm danh</th>
											</tr>
										</thead>
										<tbody>
											{dataReport.map((item, idx) =>
												<tr className="expandable-row">
													<td>{idx + 1}</td>
													<td>{moment(item.ngay_diem_danh).format('DD/MM/yyyy')}</td>
													<td>{item.ma_hoc_sinh}</td>
													<td>{item.ten_hoc_sinh}</td>
													<td>{item.ten_he}</td>
													<td>{item.ten_lop}</td>
													<td>{item.ten_trang_thai}</td>
													<td>{item.giao_vien_phu_trach}</td>
													<td>{item.gio_diem_danh}</td>
												</tr>
											)}
										</tbody>
									</table>
								}
							</div>
						</div>
					</div>
				</div>
			</div >
			{/* <!-- end chi tiet diem danh --> */}
		</>
	);
};

export default PopupTinhTrangDiemDanhBox;


import { useEffect } from "react";
import backIcon from "../../../assets/images/Back.svg";
import { useParams } from "react-router-dom";

import ChiTietThongTinHocSinhTab from "./ChiTietThongTinHocSinhTab.js";
import { useSelector } from "react-redux";
import { AppState } from "../../../context/stores/rootReducers.js";
import ChiTietKetQuaHocTapTab from "./ChiTietKetQuaHocTapTab.js";

const DashboardHoSoHocSinhChiTietPage = () => {
	const {id} = useParams();
	const { nam_hoc } = useSelector((state: AppState) => state.common);

	return (
		<>
			<div className="col-xs-12">
				<div className="col-xs-12 title-back">
					<a href="/ho-so-hoc-sinh"><img src={backIcon} />Hồ sơ học sinh</a>
				</div>
				<div className="col-xs-12 tab-cus">
					<ul className="nav nav-tabs">
						<li className="active"><a data-toggle="tab" href="#thongtinhocsinh">Thông tin học sinh</a></li>
						<li><a data-toggle="tab" href="#quatrinhhoctap">Quá trình học tập</a></li>
					</ul>
					<div className="col-xs-12 tab-content">
						<div id="thongtinhocsinh" className="tab-pane active">
							<ChiTietThongTinHocSinhTab idHocSinh={ id ?? '0'} nam_hoc={nam_hoc} />
						</div>
						<div id="quatrinhhoctap" className="tab-pane">
							<ChiTietKetQuaHocTapTab idHocSinh={ id ?? '0'} nam_hoc={nam_hoc} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardHoSoHocSinhChiTietPage;

import { useCallback, useEffect, useState } from 'react';
import classIcon from '../../../assets/images/class.svg';
import { NoDataLoadingKqht } from '../../../components/base/NoDataLoadingKqht';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import studentIcon from '../../../assets/images/user.svg';

interface ChiTietProps {
	idHocSinh: string,
	nam_hoc: string
}

interface KetQuaHocTapModelView {
	nam_hoc: string,
	lop_hoc: string,
	ketQuaXepHangModels: KetQuaXepHangModel[]
}

interface KetQuaXepHangModel {
	id: number,
	nam_hoc: string,
	hoc_ky: string,
	dm_lop_id: number,
	ten_lop: string,
	ts_hocsinh_id: number,
	diem_tb: string,
	sis_loaixephang_hocluc_id: number,
	ten_xep_hang_hocluc: string,
	sis_loaixephang_hanhkiem_id: number,
	ten_xep_hang_hanhkiem: string,
	sis_loaixephang_danhhieu_id: number,
	ten_xep_hang_danhhieu: string,
	xep_hang: string
}

interface KetQuaHocTapChiTietViewModel {
	mon_hocs: string[]
	diem_hk1s: string[]
	diem_hk2s: string[]
	diem_cns: string[]
}

interface HocSinhChiTietResponse {
	id: number
	ho_ten: string
	ma_hs: string
	ma_dinh_danh: string
	ho_ten_giao_vien_phu_trach: string
}

const ChiTietKetQuaHocTapTab = (props: ChiTietProps) => {
	const [dataReport, setDataReport] = useState<KetQuaHocTapModelView[]>([]);
	const [dataKetQuaChiTiet, setDataKetQuaChiTiet] = useState<KetQuaHocTapChiTietViewModel>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isLoadingTableChiTiet, SetIsLoadingTableChiTiet] = useState(true);
	const [tenLop, setTenLop] = useState('');
	const [namHocTmp, setNamHocTmp] = useState('');
	const [ketQuaXepHangs, setKetQuaXepHangs] = useState<KetQuaXepHangModel[]>([]);
	const [dataReportHs, setDataReportHs] = useState<HocSinhChiTietResponse>();

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)

		const resHs: BaseResponse = await rootApi.hoSo.SelectThongTinHocSinhAsync(props.idHocSinh, props.nam_hoc);
		if (resHs.isSuccess && resHs.result) {
			const listCategory = resHs.result as HocSinhChiTietResponse;
			setDataReportHs(listCategory);
		} else {
			setDataReportHs(undefined);
		}

		const res: BaseResponse = await rootApi.hoSo.SelectKetQuaHocTapAsync(props.idHocSinh);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as KetQuaHocTapModelView[];
			setDataReport(listCategory);
		}
		SetIsLoadingTableTheoHe(false);
	}, [props.idHocSinh]);

	const handleReloadDataChiTiet = useCallback(async () => {
		SetIsLoadingTableChiTiet(true)
		const res: BaseResponse = await rootApi.hoSo.SelectKetQuaHocTapChiTietAsync(props.idHocSinh, namHocTmp);
		if (res.isSuccess && res.result) {
			const resCast = res.result as KetQuaHocTapChiTietViewModel;
			setDataKetQuaChiTiet(resCast);
		}
		SetIsLoadingTableChiTiet(false);
	}, [props.idHocSinh, namHocTmp]);

	useEffect(() => {
		handleReloadData();
	}, [props.idHocSinh]);

	useEffect(() => {
		if (namHocTmp !== '')
			handleReloadDataChiTiet();
	}, [props.idHocSinh, namHocTmp]);

	useEffect(() => {
		if (dataReport.length > 0) {
			setTenLop(dataReport[0].lop_hoc); // Đặt giá trị mặc định chỉ một lần
			setNamHocTmp(dataReport[0].nam_hoc);
		}
	}, [dataReport]);

	useEffect(() => {
		if (dataReport.length > 0) {
			setKetQuaXepHangs(dataReport[0].ketQuaXepHangModels); // Đặt giá trị mặc định chỉ một lần
		}
	}, [dataReport]);
	return (
		<>
			{!isLoadingTableTheoHe && dataReport && dataReport.length > 0 &&
				<div className="col-xs-12 info-student tab-2">

					<div className="col-xs-12 student-card">
						<div className="student-header" style={{ padding: '0px 32px 10px 32px' }}>
							<img src={studentIcon} className="student-avatar" />
							<div className="student-info">
								<h2>{dataReportHs?.ho_ten}</h2>
								<span className="student-id">{dataReportHs?.ma_hs}</span>
								<p>GVCN: {dataReportHs?.ho_ten_giao_vien_phu_trach}</p>
							</div>
						</div>
						<div className="title-class"><img src={classIcon} />Lớp {tenLop}</div>
						<ul className="nav nav-tabs">
							{dataReport.map((item, idx) =>
								<li className={idx === 0 ? "active" : ""} key={idx}>
									<a data-toggle="tab" href="#tab1"
										onClick={() => {
											setKetQuaXepHangs(item.ketQuaXepHangModels)
											setTenLop(item.lop_hoc)
											setNamHocTmp(item.nam_hoc);
										}}>
										{item.nam_hoc}
									</a>
								</li>
							)}
						</ul>
					</div>

					<div className="col-xs-12 detail-student">
						<div className="col-xs-12">
							<div id="tab1" className="tab-pane active">
								<div className="box clear-padd table-container table-class-period table-process">
									<table>
										<thead>
											<tr>
												<th></th>
												<th>
													<b>Điểm TB</b>
												</th>
												<th>
													<b>Học lực</b>
												</th>
												<th>
													<b>Hạnh kiểm</b>
												</th>
												<th>
													<b>Danh hiệu</b>
												</th>
											</tr>
										</thead>
										<tbody>
											{ketQuaXepHangs.map((item) =>
												<tr>
													<td>
														<b>{item.hoc_ky === "1"
															? "Học kỳ 1" : item.hoc_ky === "2"
																? "Học kỳ 2" : "Cả năm"}</b>
													</td>
													<td>
														<p>{item.diem_tb}</p>
													</td>
													<td>
														<p>{item.ten_xep_hang_hocluc}</p>
													</td>
													<td>
														<p>{item.ten_xep_hang_hanhkiem}</p>
													</td>
													<td>
														<p>{item.ten_xep_hang_danhhieu}</p>
													</td>
												</tr>
											)}
											{ketQuaXepHangs.length === 0 &&
												<tr>
													<td colSpan={5}>
														<p style={{ marginTop: '5px', marginBottom: '5px' }}>
															<NoDataLoadingKqht />
														</p>
													</td>
												</tr>
											}
										</tbody>
									</table>
								</div>

								<div className="col-xs-12 flex-box timetable mar-bot-30">
									<div className="col-sm-12 text-left">
										Chi tiết kết quả học tập
									</div>
								</div>

								<div className="box clear-padd table-container table-class-period table-process">
									{ketQuaXepHangs.length > 0 && dataKetQuaChiTiet?.mon_hocs && dataKetQuaChiTiet?.mon_hocs.length > 0 &&
										<table>
											<thead>
												<tr>
													<th></th>
													{dataKetQuaChiTiet?.mon_hocs.map((item) =>
														<th>{item}</th>
													)}

												</tr>
											</thead>
											<tbody>
												{dataKetQuaChiTiet?.diem_hk1s && dataKetQuaChiTiet?.diem_hk1s.length > 0 &&
													<tr>
														<td>
															<b>Học kỳ 1</b>
														</td>
														{dataKetQuaChiTiet?.diem_hk1s.map((item) =>
															<td>
																<p>{item}</p>
															</td>
														)}
													</tr>
												}
												{dataKetQuaChiTiet?.diem_hk2s && dataKetQuaChiTiet?.diem_hk2s.length > 0 &&
													<tr>
														<td>
															<b>Học kỳ 2</b>
														</td>
														{dataKetQuaChiTiet?.diem_hk2s.map((item) =>
															<td>
																<p>{item}</p>
															</td>
														)}
													</tr>
												}
												{dataKetQuaChiTiet?.diem_cns && dataKetQuaChiTiet?.diem_cns.length > 0 &&
													<tr>
														<td>
															<b>Cả năm</b>
														</td>
														{dataKetQuaChiTiet?.diem_cns.map((item) =>
															<td>
																<p>{item}</p>
															</td>
														)}
													</tr>
												}
											</tbody>
										</table>
									}
									{(ketQuaXepHangs.length === 0 || !dataKetQuaChiTiet?.mon_hocs || dataKetQuaChiTiet?.mon_hocs.length === 0) &&
										<p style={{ marginTop: '15px', marginBottom: '15px' }}>
											<NoDataLoadingKqht />
										</p>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{(!dataReport || dataReport.length === 0) &&
				<h4 style={{ marginTop: '20px', marginLeft: '20px' }}>Không có dữ liệu kết quả học tập</h4>
			}
		</>
	);
};

export default ChiTietKetQuaHocTapTab;

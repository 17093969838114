
import Header from "../../layouts/admin/Header";
import settingIcon from "../../assets/images/setting.svg";
import avatarIcon from "../../assets/images/avt.svg";
import leftIcon from "../../assets/images/left.svg";
import rightIcon from "../../assets/images/right.svg";
import { useEffect, useState } from "react";
import { SelectBoxKhoi } from "../../components/shared/mega/combobox-khoi/SelectBoxKhoi";
import { SelectBoxHe } from "../../components/shared/mega/combobox-he/SelectBoxHe";
import { SelectBoxlop } from "../../components/shared/mega/combobox-lop/SelectBoxlop";
import { SelectBoxTrangThai } from "../../components/shared/mega/combobox-trangthai/SelectBoxTrangThai";
import { rootApi } from "../../context/api/rootApi";
import { useSelector } from "react-redux";
import { AppState } from "../../context/stores/rootReducers";
import moment from "moment";
import studentIcon from '../../assets/images/user.svg';
import { useDebounce } from "use-debounce";


interface HocSinhChiTietResponse {
	id: number
	ho_ten: string
	ma_hs: string
	ma_dinh_danh: string
	ho_ten_giao_vien_phu_trach: string
	avatar: string
	ngay_sinh: string
	dm_quoctich_id: number
	dm_gioitinh_id: number
	dien_thoai_hocsinh: string
	email_hocsinh: string
	dia_chi_hientai: string
	dm_he_id: number
	dm_truong_id: number
	dm_khoi_id: number
	dm_lop_id: number
	dm_trangthaihocsinh_id: number,
	ten_khoi: string,
	ten_lop: string,
	ten_he: string,
	dm_trangthaihocsinh_name: string,
	dm_quoctich_name: string,
	dm_gioitinh_name: string,
	dia_chi_tt: string,
	ho_ten_cha: string,
	ho_ten_me: string,
	dien_thoai_me: string,
	dien_thoai_cha: string,
}

const DashboardHoSoHocSinhPage = () => {
	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [isVisible, setIsVisible] = useState(false);
	const toggleCheckboxContent = () => {
		setIsVisible(prevState => !prevState);
	};
	const [idKhoi, setIdKhoi] = useState(0);
	const [idHe, setIdHe] = useState(0);
	const [idLop, setIdLop] = useState(0);
	const [idTrangThai, setIdTrangThai] = useState(0);
	const [studentName, setStudentName] = useState("");
	const [debouncedStudentName, debouncedStateName] = useDebounce(studentName, 2000, {
		leading: false,
		trailing: true,
		maxWait: 2000,
	});
	const [studentCode, setStudentCode] = useState("");
	const [debouncedStudentNameCode, debouncedStateCode] = useDebounce(studentCode, 2000, {
		leading: false,
		trailing: true,
		maxWait: 2000,
	});
	const [students, setStudents] = useState<HocSinhChiTietResponse[]>([]); // State lưu danh sách học sinh

	useEffect(() => {
		if (debouncedStudentName !== undefined) {
			fetchStudentData()
		}
	}, [debouncedStudentName]);

	useEffect(() => {
		if (debouncedStudentNameCode !== undefined) {
			fetchStudentData()
		}
	}, [debouncedStudentNameCode]);

	const itemsPerPage = 20; // Số lượng học sinh trên mỗi trang
	const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
	const [totalPages, setTotalPages] = useState(1);

	const fetchStudentData = async () => {
		try {
			const res = await rootApi.hoSo.SelectDanhSachHocSinhAsync(dm_coso_id, dm_truong_id, nam_hoc, idKhoi, idHe, idLop, studentName, studentCode, idTrangThai);
			if (res.isSuccess && res.result) {
				const listCategory = res.result as HocSinhChiTietResponse[];
				setStudents(listCategory);
				setTotalPages(Math.ceil(listCategory.length / itemsPerPage));
			} else {
				setStudents([]);
				setTotalPages(1);
			}
		} catch (error) {
			console.error("Lỗi khi lấy dữ liệu học sinh:", error);
		}
	};

	useEffect(() => {
		fetchStudentData()
	}, [dm_coso_id, dm_truong_id, nam_hoc, idKhoi, idHe, idLop, idTrangThai])

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const displayedStudents = students.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	// Tính toán phạm vi trang hiển thị
	const maxPagesToShow = 5;
	let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
	let endPage = startPage + maxPagesToShow - 1;

	if (endPage > totalPages) {
		endPage = totalPages;
		startPage = Math.max(1, endPage - maxPagesToShow + 1);
	}

	const getInitialState = (key: string, defaultValue: boolean) => {
		const storedValue = localStorage.getItem(key);
		return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
	};

	// State variables with values loaded from localStorage or defaulted
	const [showAvatar, setShowAvatar] = useState(() => getInitialState("showAvatar", true));
	const [showSoDienThoai, setShowSoDienThoai] = useState(() => getInitialState("showSoDienThoai", false));
	const [showTenHe, setShowTenHe] = useState(() => getInitialState("showTenHe", true));
	const [showTrangThai, setShowTrangThai] = useState(() => getInitialState("showTrangThai", true));
	const [showTenBo, setShowTenBo] = useState(() => getInitialState("showTenBo", true));
	const [showTenMe, setShowTenMe] = useState(() => getInitialState("showTenMe", true));
	const [showDienThoaiBo, setShowDienThoaiBo] = useState(() => getInitialState("showDienThoaiBo", false));
	const [showDienThoaiMe, setShowDienThoaiMe] = useState(() => getInitialState("showDienThoaiMe", false));

	// Update localStorage whenever any checkbox value changes
	useEffect(() => {
		localStorage.setItem("showAvatar", JSON.stringify(showAvatar));
	}, [showAvatar]);

	useEffect(() => {
		localStorage.setItem("showSoDienThoai", JSON.stringify(showSoDienThoai));
	}, [showSoDienThoai]);

	useEffect(() => {
		localStorage.setItem("showTenHe", JSON.stringify(showTenHe));
	}, [showTenHe]);

	useEffect(() => {
		localStorage.setItem("showTrangThai", JSON.stringify(showTrangThai));
	}, [showTrangThai]);

	useEffect(() => {
		localStorage.setItem("showTenBo", JSON.stringify(showTenBo));
	}, [showTenBo]);

	useEffect(() => {
		localStorage.setItem("showTenMe", JSON.stringify(showTenMe));
	}, [showTenMe]);

	useEffect(() => {
		localStorage.setItem("showDienThoaiBo", JSON.stringify(showDienThoaiBo));
	}, [showDienThoaiBo]);

	useEffect(() => {
		localStorage.setItem("showDienThoaiMe", JSON.stringify(showDienThoaiMe));
	}, [showDienThoaiMe]);
	
	return (
		<>
			<div className="col-xs-12 header-container">
				<div className="col-xs-12 frame-box">
					<Header />
				</div>
				<div className="col-xs-12 title-detail">
					<span>Thông tin tìm kiếm</span>
				</div>
				<div className="col-xs-12 box-content box diemdanh box-search">
					<div className="col-xs-12 frame-box">
						<div className="col-xs-12 form_inline">
							<div className="col-xs-6 form-group">
								<label>Khối</label>
								<div className="custom-dropdown" style={{ zIndex: 1 }}>
									<SelectBoxKhoi
										value={idKhoi}
										onValueChanged={(value) => {
											setIdKhoi(value)
										}} />
								</div>
							</div>
							<div className="col-xs-6 form-group">
								<label>Họ và tên học sinh</label>
								<div className="custom-dropdown">
									<input type="text" name="" className="form-control" placeholder="Nhập họ và tên học sinh" style={{ fontSize: '12px', height: '40px' }}
										value={studentName}
										onChange={e => setStudentName(e.target.value)} />
								</div>
							</div>
						</div>
						<div className="col-xs-12 form_inline">
							<div className="col-xs-6 form-group">
								<label>Hệ</label>
								<div className="custom-dropdown" style={{ zIndex: 1 }}>
									<SelectBoxHe
										value={idHe}
										onValueChanged={(value) => {
											setIdHe(value)
										}}
										idKhoi={idKhoi}
									/>
								</div>
							</div>
							<div className="col-xs-6 form-group">
								<label>Mã học sinh</label>
								<div className="custom-dropdown">
									<input type="text" name="" className="form-control" placeholder="Nhập mã học sinh" style={{ fontSize: '12px', height: '40px' }}
										value={studentCode}
										onChange={e => setStudentCode(e.target.value)} />
								</div>
							</div>
						</div>
						<div className="col-xs-12 form_inline">
							<div className="col-xs-6 form-group">
								<label>Lớp</label>
								<div className="custom-dropdown" style={{ zIndex: 1 }}>
									<SelectBoxlop
										value={idLop}
										onValueChanged={(value) => {
											setIdLop(value)
										}}
										idKhoi={idKhoi}
										idHe={idHe}
									/>
								</div>
							</div>
							<div className="col-xs-6 form-group">
								<label>Trạng thái</label>
								<div className="custom-dropdown" style={{ zIndex: 1 }} >
									<SelectBoxTrangThai
										value={idTrangThai}
										onValueChanged={(value) => {
											setIdTrangThai(value)
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xs-12 checkbox-container">
					<div id="checkboxContent" className={`checkbox-content ${isVisible ? 'show' : ''}`}>
						<div className="checkbox-group">
							<label>
								<input type="checkbox" checked={showAvatar}
									onChange={() => setShowAvatar((prev: boolean) => !prev)} />
								Avatar
							</label>
							<label>
								<input type="checkbox" checked={showSoDienThoai}
									onChange={() => setShowSoDienThoai((prev: boolean) => !prev)} />
								Số điện thoại
							</label>
							<label>
								<input type="checkbox" checked={showTenHe}
									onChange={() => setShowTenHe((prev: boolean) => !prev)} />
								Tên hệ
							</label>
							<label>
								<input type="checkbox" checked={showTrangThai}
									onChange={() => setShowTrangThai((prev: boolean) => !prev)} />
								Trạng thái
							</label>
						</div>
						<div className="checkbox-group">
							<label>
								<input type="checkbox" checked={showTenBo}
									onChange={() => setShowTenBo((prev: boolean) => !prev)} />
								Tên bố
							</label>
							<label>
								<input type="checkbox" checked={showDienThoaiBo}
									onChange={() => setShowDienThoaiBo((prev: boolean) => !prev)} />
								Điện thoại bố
							</label>
							<label>
								<input type="checkbox" checked={showTenMe}
									onChange={() => setShowTenMe((prev: boolean) => !prev)} />
								Tên mẹ
							</label>
							<label>
								<input type="checkbox" checked={showDienThoaiMe}
									onChange={() => setShowDienThoaiMe((prev: boolean) => !prev)} />
								Điện thoại mẹ
							</label>
						</div>
					</div>
					<div className="settings-icon">
						<img src={settingIcon} alt="Settings" onClick={toggleCheckboxContent} />
					</div>
				</div>
				<div className="col-xs-12 frame-box">
					<div className="box clear-padd table-container table-student" style={{ marginTop: '5px' }}>
						<table>
							<thead>
								<tr>
									<th>STT</th>
									{showAvatar && <th>Avatar</th>}
									<th>Mã học sinh</th>
									<th>Tên học sinh</th>
									{showSoDienThoai && <th>Số điện thoại</th>}
									<th>Ngày sinh</th>
									<th>Giới tính</th>
									<th>Lớp</th>
									{showTenHe && <th>Hệ</th>}
									{showTrangThai && <th>Trạng thái</th>}
									{showTenBo && <th>Tên bố</th>}
									{showDienThoaiBo && <th>Số điện thoại Bố</th>}
									{showTenMe && <th>Tên mẹ</th>}
									{showDienThoaiMe && <th>Số điện thoại Mẹ</th>}
								</tr>
							</thead>
							<tbody>
								{displayedStudents.map((student, index) => (
									<tr key={student.id}>
										<td className="text-center">{index + 1}</td>
										{showAvatar && <td className="text-center"><img src={studentIcon} alt="" style={{ width: '32px', height: '32px' }} /></td>}
										<td>{student.ma_hs}</td>
										<td><a href={'/chi-tiet-ho-so-hoc-sinh/' + student.id}>{student.ho_ten}</a></td>
										{showSoDienThoai && <td>{student.dien_thoai_hocsinh}</td>}
										<td>{moment(student.ngay_sinh).format('DD/MM/YYYY')}</td>
										<td>{student.dm_gioitinh_name}</td>
										<td>{student.ten_lop}</td>
										{showTenHe && <td>{student.ten_he}</td>}
										{showTrangThai && <td>{student.dm_trangthaihocsinh_name}</td>}
										{showTenBo && <td>{student.ho_ten_cha}</td>}
										{showDienThoaiBo && <td>{student.dien_thoai_cha}</td>}
										{showTenMe && <td>{student.ho_ten_me}</td>}
										{showDienThoaiMe && <td>{student.dien_thoai_me}</td>}
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="col-xs-12 text-center">
					<div style={{float: 'left', marginTop: '10px'}}><span>Tổng số:</span> <b>{students.length}</b> học sinh</div>
						<ul className="pagination pagination-cus" style={{float:'right'}}>
							{/* Nút "Về trang đầu" */}
							<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
								<a className="page-link" onClick={() => handlePageChange(1)}>Trang đầu</a>
							</li>

							<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
								<a className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
									<img src={leftIcon} alt="Previous" />
								</a>
							</li>

							{/* Hiển thị các trang trong phạm vi */}
							{Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
								<li key={page} className={`page-item ${currentPage === page ? "active" : ""}`}>
									<a className="page-link" onClick={() => handlePageChange(page)}>
										{page}
									</a>
								</li>
							))}

							<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
								<a className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
									<img src={rightIcon} alt="Next" />
								</a>
							</li>

							{/* Nút "Đến trang cuối" */}
							<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
								<a className="page-link" onClick={() => handlePageChange(totalPages)}>Trang cuối</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardHoSoHocSinhPage;

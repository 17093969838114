import { useMemo, useState, useCallback, useEffect } from 'react';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxGrid,
	DxLegend,
	DxMargin,
	DxSeries,
	DxTooltip,
} from '../../../components/common/DxCharts';
import { useLookupKhoi } from '../../../components/shared/mega';
import { useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { DiemDanhResponse } from '../../../context/models/mega/DiemDanhResponse';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { rootApi } from '../../../context/api/rootApi';

const energySources = [
	{ value: 'nam', name: 'Nam' },
	{ value: 'nu', name: 'Nữ' },
	{ value: 'moi', name: 'Học sinh mới' },
	{ value: 'chuyen_truong', name: 'Chuyển trường' },
	{ value: 'da_tot_nghiep', name: 'Đã tốt nghiệp' },
];

interface BaoCaoHocSinhPieChartResponse {
	dm_truong_id: number;
	dm_khoi_id: number;
	ten_truong: string;
	ten_truong_en: string;
	ten_khoi: string;
	ten_khoi_en: string;
	hocsinh_total: number;
}

const BaoCaoTrinhTrangHocSinhChart = () => {

	const { dm_coso_id, dm_truong_id, nam_hoc } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<BaoCaoHocSinhPieChartResponse[]>([]);

    const handleReloadData = useCallback(async () => {
		const res: BaseResponse = await rootApi.baoCao.GetBaoCaoHocSinhTheoKhoiAsync(dm_coso_id, dm_truong_id, nam_hoc);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as BaoCaoHocSinhPieChartResponse[];
			setDataReport(listCategory);
		} else {
			setDataReport([]);
		}
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dm_coso_id, dm_truong_id, nam_hoc]);

	const khoiGrouped = useMemo(() => {
		const huyenGrouped = dataReport.reduce((khoiGroupData: BaoCaoHocSinhPieChartResponse[], source) => {
			const exited = khoiGroupData.find((x: BaoCaoHocSinhPieChartResponse) => x.dm_khoi_id === source.dm_khoi_id);
			if (!exited) {
				khoiGroupData.push(source);
			}
			return khoiGroupData;
		}, []);
		return huyenGrouped;
	}, [dataReport]);

	const khoiSoLieuGrouped = useMemo(() => {
		const khoiSoLieuGrouped = khoiGrouped.map((khoi) => {
			let result: any = { ...khoi, nam: 0, nu: 0, moi: 0, chuyen_truong: 0, da_tot_nghiep: 0 };
			const soLieuKhois = dataReport.filter((map_khoi) => map_khoi.dm_khoi_id === khoi.dm_khoi_id);
			if (soLieuKhois) {
				soLieuKhois.forEach((solieu) => {
					result = {
						...result,
					};
				});
			}
			return result;
		});
		return khoiSoLieuGrouped.sort((a, b) => (a.ten_khoi > b.ten_khoi ? 1 : -1));
	}, [khoiGrouped, dataReport]);

	return (
		<>
			<DxChart palette='Dark Violet' dataSource={khoiSoLieuGrouped} height={320}>
				<DxCommonSeriesSettings argumentField='ten_khoi' type={'line'} />
				{energySources.map((item) => (
					<DxSeries key={item.value} valueField={item.value} name={item.name} />
				))}
				<DxMargin bottom={20} />
				<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
					<DxGrid visible={true} />
				</DxArgumentAxis>
				<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' />
				{/* <DxTitle text='Energy Consumption in 2004'></DxTitle> */}
				<DxTooltip enabled={true} />
			</DxChart>
		</>
	);
};

export default BaoCaoTrinhTrangHocSinhChart;

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootApi } from '../../../context/api/rootApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { AppState } from '../../../context/stores/rootReducers';
import { SelectBoxTuan } from '../../../components/shared/mega/combobox-tuan/SelectBoxTuan';
import { NoDataLoading } from '../../../components/base/NoDataLoading';
import { Box } from 'devextreme-react';


interface ChiTietProps {
	idHocSinh: string,
	nam_hoc: string
}

interface ThoiKhoaBieuResponseModel {
	thus: ThuTrongThuanViewModel[]
	tkbs: TietTheoNgayViewModel[]
}

interface ThuTrongThuanViewModel {
	ten_thu: string
	ngay_thang: string
}

interface TietTheoNgayViewModel {
	tiet: string
	thoi_gian: string
	mon_giao_viens: MonTheoNgayViewModel[]
}

interface MonTheoNgayViewModel {
	ten_mon: string
	giao_vien: string
}

const ChiTietThongTinHocSinhTab_Tkb = (props: ChiTietProps) => {
	const { nam_hoc, tuan } = useSelector((state: AppState) => state.common);
	const [dataReport, setDataReport] = useState<ThoiKhoaBieuResponseModel>();
	const [isLoadingTableTheoHe, SetIsLoadingTableTheoHe] = useState(true);
	const [isShowPopup, setShowPopup] = useState(false);
	const [idLop, setIdLop] = useState(0);
	const [xepHang, setXepHang] = useState('');

	const handleReloadData = useCallback(async () => {
		SetIsLoadingTableTheoHe(true)
		const res: BaseResponse = await rootApi.hoSo.SelectThoiKhoaBieuHocSinhAsync(props.idHocSinh, props.nam_hoc, tuan);
		if (res.isSuccess && res.result) {
			const listCategory = res.result as ThoiKhoaBieuResponseModel;
			setDataReport(listCategory);
		} else {
			setDataReport(undefined);
		}
		SetIsLoadingTableTheoHe(false);
	}, [props.idHocSinh, nam_hoc, tuan]);

	useEffect(() => {
		handleReloadData();
	}, [props.idHocSinh, nam_hoc, tuan]);

	return (
		<>
			<div className="col-xs-12 flex-box timetable">
				<div className="col-xs-6">
					Thời khoá biểu
				</div>
				<div className="col-xs-6">
					<SelectBoxTuan />
				</div>
			</div>
			<div className="col-xs-12 frame-box">
				<div className="box clear-padd table-container table-class-period">
					{!isLoadingTableTheoHe && (!dataReport || dataReport.thus.length === 0) && 
					<div style={{marginTop: '20px', marginBottom: '20px'}}><NoDataLoading /></div>
					}
					{dataReport && dataReport.thus.length > 0 &&
						<table>
							<thead>
								<tr>
									<th></th>
									{dataReport?.thus.map((item) =>
										<th>
											<b>{item.ten_thu}</b>
											<p>{item.ngay_thang}</p>
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{dataReport?.tkbs.map((item) =>
									<tr>
										<td>
											<b>{item.tiet}</b>
											<p>{item.thoi_gian}</p>
										</td>
										{item.mon_giao_viens.map((item1) =>
											<td>
												<b>{item1.ten_mon}</b>
												<p>{item1.giao_vien}</p>
											</td>
										)}
									</tr>
								)}

							</tbody>
						</table>
					}
				</div>
			</div >
		</>
	);
};

export default ChiTietThongTinHocSinhTab_Tkb;
